import { takeEvery, put } from 'redux-saga/effects';
import { ServiceActionTypes } from './types';
import ServiceService from '../../services/service-service';
import { IServiceInput, IServiceUpdateInput } from '../../models/IService';
import {
  addServiceSuccess,
  deleteServiceSuccess,
  getServicesSuccess,
  onServiceRequestFailure,
  updateServiceSuccess,
} from './actions';
import { IQuery } from '../../models/IQuery';

const serviceService = new ServiceService();

// //TODO catch response fail
function* addServiceWorker(action: {
  type: string;
  payload: {
    serviceInput: IServiceInput;
    locationId: string;
    assignOptions: { masters?: string[]; assignToAll?: boolean };
  };
}) {
  try {
    const response = yield serviceService.createService(
      action.payload.serviceInput,
      action.payload.locationId,
      action.payload.assignOptions
    );
    yield put(addServiceSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onServiceRequestFailure(e.message));
  }
}

function* getServicesWorker(action: {
  type: string;
  payload: { locationId: string; pagination: IQuery; filterBy?: any };
}) {
  try {
    const response = yield serviceService.getServices(
      action.payload.locationId,
      action.payload.pagination,
      action.payload.filterBy
    );
    yield put(getServicesSuccess(response.data.services, response.data.total));
  } catch (e) {
    console.log(e);
    yield put(onServiceRequestFailure(e.message));
  }
}

function* updateServiceWorker(action: { type: string; payload: { service: IServiceUpdateInput; serviceId: string } }) {
  try {
    const response = yield serviceService.updateService(action.payload.service, action.payload.serviceId);
    yield put(updateServiceSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onServiceRequestFailure(e.message));
  }
}

function* deleteServiceWorker(action: { type: string; payload: { serviceId: string; locationId: string } }) {
  try {
    yield serviceService.deleteService(action.payload.serviceId, action.payload.locationId);
    yield put(deleteServiceSuccess(action.payload.serviceId));
  } catch (e) {
    console.log(e);
    yield put(onServiceRequestFailure(e.message));
  }
}

export function* watchService() {
  yield takeEvery(ServiceActionTypes.ADD_SERVICE_REQUEST, addServiceWorker);
  yield takeEvery(ServiceActionTypes.DELETE_SERVICE_REQUEST, deleteServiceWorker);
  yield takeEvery(ServiceActionTypes.UPDATE_SERVICE_REQUEST, updateServiceWorker);
  yield takeEvery(ServiceActionTypes.GET_SERVICES_REQUEST, getServicesWorker);
}

const serviceWatchers: any = [watchService()];

export default serviceWatchers;
