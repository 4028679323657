import React from 'react';

export const CalendarIcon = () => (
  <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 3.73333H3V1.4C3 0.628133 3.673 0 4.5 0H5.5C6.327 0 7 0.628133 7 1.4V3.73333H23V1.4C23 0.628133 23.673 0 24.5 0H25.5C26.327 0 27 0.628133 27 1.4V3.73333H27.5C28.8785 3.73333 30 4.78007 30 6.06667V25.6667C30 26.9533 28.8785 28 27.5 28H2.5C1.1215 28 0 26.9533 0 25.6667V6.06667C0 4.78007 1.1215 3.73333 2.5 3.73333ZM6 4.2V1.4C6 1.1424 5.776 0.933333 5.5 0.933333H4.5C4.224 0.933333 4 1.1424 4 1.4V4.2V6.06667C4 6.32427 4.224 6.53333 4.5 6.53333H5.5C5.776 6.53333 6 6.32427 6 6.06667V4.2ZM26 4.2V1.4C26 1.1424 25.776 0.933333 25.5 0.933333H24.5C24.224 0.933333 24 1.1424 24 1.4V4.2V6.06667C24 6.32427 24.224 6.53333 24.5 6.53333H25.5C25.776 6.53333 26 6.32427 26 6.06667V4.2ZM1 25.6667C1 26.4385 1.673 27.0667 2.5 27.0667H27.5C28.327 27.0667 29 26.4385 29 25.6667V12.1333H1V25.6667ZM1 11.2H29V6.06667C29 5.2948 28.327 4.66667 27.5 4.66667H27V6.06667C27 6.83853 26.327 7.46667 25.5 7.46667H24.5C23.673 7.46667 23 6.83853 23 6.06667V4.66667H7V6.06667C7 6.83853 6.327 7.46667 5.5 7.46667H4.5C3.673 7.46667 3 6.83853 3 6.06667V4.66667H2.5C1.673 4.66667 1 5.2948 1 6.06667V11.2Z" fill="black"/>
    <path d="M9.49999 6.06665H20.5C20.7765 6.06665 21 6.27525 21 6.53332V9.33332C21 9.59138 20.7765 9.79998 20.5 9.79998H9.49999C9.22349 9.79998 8.99999 9.59138 8.99999 9.33332V6.53332C8.99999 6.27525 9.22349 6.06665 9.49999 6.06665ZM9.99999 8.86665H20V6.99998H9.99999V8.86665Z" fill="black"/>
    <path d="M23.5 13.5333H26.5C26.7765 13.5333 27 13.7419 27 14V16.3333C27 16.5914 26.7765 16.8 26.5 16.8H23.5C23.2235 16.8 23 16.5914 23 16.3333V14C23 13.7419 23.2235 13.5333 23.5 13.5333ZM24 15.8667H26V14.4667H24V15.8667Z" fill="black"/>
    <path d="M17.0001 13.5333H20.0001C20.2766 13.5333 20.5001 13.7419 20.5001 14V16.3333C20.5001 16.5914 20.2766 16.8 20.0001 16.8H17.0001C16.7236 16.8 16.5001 16.5914 16.5001 16.3333V14C16.5001 13.7419 16.7236 13.5333 17.0001 13.5333ZM17.5001 15.8667H19.5001V14.4667H17.5001V15.8667Z" fill="black"/>
    <path d="M10 13.5333H13C13.2765 13.5333 13.5 13.7419 13.5 14V16.3333C13.5 16.5914 13.2765 16.8 13 16.8H10C9.7235 16.8 9.5 16.5914 9.5 16.3333V14C9.5 13.7419 9.7235 13.5333 10 13.5333ZM10.5 15.8667H12.5V14.4667H10.5V15.8667Z" fill="black"/>
    <path d="M3.5 13.5333H6.5C6.7765 13.5333 7 13.7419 7 14V16.3333C7 16.5914 6.7765 16.8 6.5 16.8H3.5C3.2235 16.8 3 16.5914 3 16.3333V14C3 13.7419 3.2235 13.5333 3.5 13.5333ZM4 15.8667H6V14.4667H4V15.8667Z" fill="black"/>
    <path d="M23.5 18.2H26.5C26.7765 18.2 27 18.4086 27 18.6666V21C27 21.258 26.7765 21.4667 26.5 21.4667H23.5C23.2235 21.4667 23 21.258 23 21V18.6666C23 18.4086 23.2235 18.2 23.5 18.2ZM24 20.5333H26V19.1333H24V20.5333Z" fill="black"/>
    <path d="M17.0001 18.2H20.0001C20.2766 18.2 20.5001 18.4086 20.5001 18.6666V21C20.5001 21.258 20.2766 21.4667 20.0001 21.4667H17.0001C16.7236 21.4667 16.5001 21.258 16.5001 21V18.6666C16.5001 18.4086 16.7236 18.2 17.0001 18.2ZM17.5001 20.5333H19.5001V19.1333H17.5001V20.5333Z" fill="black"/>
    <path d="M10 18.2H13C13.2765 18.2 13.5 18.4086 13.5 18.6666V21C13.5 21.258 13.2765 21.4667 13 21.4667H10C9.7235 21.4667 9.5 21.258 9.5 21V18.6666C9.5 18.4086 9.7235 18.2 10 18.2ZM10.5 20.5333H12.5V19.1333H10.5V20.5333Z" fill="black"/>
    <path d="M3.5 18.2H6.5C6.7765 18.2 7 18.4086 7 18.6666V21C7 21.258 6.7765 21.4667 6.5 21.4667H3.5C3.2235 21.4667 3 21.258 3 21V18.6666C3 18.4086 3.2235 18.2 3.5 18.2ZM4 20.5333H6V19.1333H4V20.5333Z" fill="black"/>
    <path d="M23.5 22.8667H26.5C26.7765 22.8667 27 23.0753 27 23.3333V25.6667C27 25.9247 26.7765 26.1333 26.5 26.1333H23.5C23.2235 26.1333 23 25.9247 23 25.6667V23.3333C23 23.0753 23.2235 22.8667 23.5 22.8667ZM24 25.2H26V23.8H24V25.2Z" fill="black"/>
    <path d="M17.0001 22.8667H20.0001C20.2766 22.8667 20.5001 23.0753 20.5001 23.3333V25.6667C20.5001 25.9247 20.2766 26.1333 20.0001 26.1333H17.0001C16.7236 26.1333 16.5001 25.9247 16.5001 25.6667V23.3333C16.5001 23.0753 16.7236 22.8667 17.0001 22.8667ZM17.5001 25.2H19.5001V23.8H17.5001V25.2Z" fill="black"/>
    <path d="M9.8839 22.568L12.0334 24.9755L13.1464 23.9367L13.8534 24.5966L12.3534 25.9966C12.2599 26.0848 12.1324 26.1333 11.9999 26.1333C11.9924 26.1333 11.9849 26.1333 11.9774 26.1328C11.8369 26.1268 11.7059 26.0661 11.6154 25.9658L9.1154 23.1658L9.8839 22.568Z" fill="black"/>
    <path d="M3.88414 22.568L6.03364 24.9755L7.14664 23.9367L7.85364 24.5966L6.35364 25.9966C6.26014 26.0848 6.13264 26.1333 6.00014 26.1333C5.99264 26.1333 5.98514 26.1333 5.97764 26.1328C5.83714 26.1268 5.70614 26.0661 5.61564 25.9658L3.11564 23.1658L3.88414 22.568Z" fill="black"/>
  </svg>
);
