import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import { RESERVATION_INTERVAL_IN_MINUTES, weekDays } from '../const';
import { ILocation } from '../models/ILocation';
import { IService } from '../models/IService';
import { IMaster } from '../models/IUser';
import { IWorkingHours } from '../models/IWorkingHours';

export const objectToArray = (obj: any): any[] => {
  const temp: any[] = [];

  Object.keys(obj).forEach((key) => temp.push(obj[key]));

  return temp;
};

export const objectToPropsArray = (obj: any): any[] => {
  const temp: any[] = [];

  Object.keys(obj).forEach((key) => temp.push({ [key]: obj[key] }));

  return temp;
};

export const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

export const copyToClipboard = (text: string): void => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

type FormWorkingHours = { [key: string]: Moment[] };
export const workingHoursToFormData = (workingHours: IWorkingHours): FormWorkingHours => {
  const formWorkingHours: FormWorkingHours | any = {};
  for (let [day, value] of Object.entries(workingHours)) {
    value.forEach((time: string, index: number) => {
      const [from, to] = time.split('-');
      if (index === 0) {
        formWorkingHours[`${day}-${'from-start'}`] = moment(from, 'HH:mm');
        formWorkingHours[`${day}-${'from-end'}`] = moment(to, 'HH:mm');
      } else {
        formWorkingHours[`${day}-${'to-start'}`] = moment(from, 'HH:mm');
        formWorkingHours[`${day}-${'to-end'}`] = moment(to, 'HH:mm');
      }
    });
  }

  return formWorkingHours;
};

export const formWithWorkingDaysToCleanObject = (object: any, form: any) => {
  for (let [key, value] of Object.entries(form)) {
    if (weekDays.includes(key.replace('-from', '').replace('-to', '')) && value) {
      // @ts-ignore
      const workingTime = `${moment(value[0]).format('HH:mm')}-${moment(value[1]).format('HH:mm')}`;
      if (object.workingHours === undefined) object.workingHours = {};
      if (object.workingHours[key.replace('-from', '').replace('-to', '')]) {
        object.workingHours[key.replace('-from', '').replace('-to', '')].push(workingTime);
      } else {
        object.workingHours[key.replace('-from', '').replace('-to', '')] = [workingTime];
      }
    } else {
      if (value) object[key] = value;
    }
  }
};

export const getAbsoluteHeight = (el: any): number => {
  const styles = window.getComputedStyle(el);
  const margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

  return Math.ceil(el.offsetHeight + margin);
};

export const convertDayToNumber = (day: string) => {
  switch (day) {
    case 'sunday':
      return 0;
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
  }
};
type Picked = any;
type NotPicked = any;
export const splitObject = (object: any, fieldsToPick: string[]): [Picked, NotPicked] => {
  const picked: Picked = {};
  const notPicked: NotPicked = {};

  Object.keys(object).forEach((key) => {
    if (!fieldsToPick.includes(key)) {
      picked[key] = object[key];
    } else {
      notPicked[key] = object[key];
    }
  });

  return [picked, notPicked];
};

export const replaceInArray = (array: any[], _id: any, replaceWith: any): any[] => {
  const index = array.findIndex((elm) => elm._id === _id);
  array[index] = replaceWith;
  return array;
};

export const dateToMinutes = (date: Moment): number => {
  let hh = date.get('hour');
  let mm = date.get('minute');

  return hh * 60 + mm;
};

export const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getReservationDifference = (res: any, resForm: any) => {
  const reservationForm = { ...resForm };
  const reservation = { ...res };

  const difference: any = {};
  Object.keys(reservationForm).forEach((key) => {
    let resField = reservation[key];
    switch (key) {
      case 'discounts':
        resField = (reservation[key] as any[]).map((d) => d._id.toString());
        break;
      default:
        if (reservation[key] && reservation[key]._id && reservation[key]._id !== undefined) {
          resField = reservation[key]._id.toString();
        }
    }

    if (resField !== undefined && resField !== reservationForm[key]) {
      if (key === 'discounts') {
        if (!_.isEqual(_.sortBy(resField), _.sortBy(reservationForm[key]))) {
          difference[key] = reservationForm[key];
        }
      } else if (key === 'reservedAt') {
        if (new Date(reservation[key]).toISOString() !== new Date(reservationForm[key]).toISOString()) {
          difference[key] = reservationForm[key];
        }
      } else {
        difference[key] = reservationForm[key];
      }
    }
  });

  return difference;
};

export const cleanObject = (obj: any) => {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === '' ||
      (typeof obj[propName] === 'object' && Object.keys(obj[propName]).length === 0)
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const isArrayHasSameElements = (arr: any[], arr2: any[]): boolean => {
  if (arr.length !== arr2.length) return false;

  let isSame = true;

  arr.forEach((e) => {
    if (!arr2.includes(e)) isSame = false;
  });

  return isSame;
};

export const splitArrayBy = (arr: any[], conditionFunc: (elm: any) => boolean): any => {
  const pass: any = [];
  const notPass: any = [];

  arr.forEach((elm) => {
    conditionFunc(elm) ? pass.push(elm) : notPass.push(elm);
  });

  return [pass, notPass];
};

export const getDaysBetweenDates = (start: Date, end: Date, dayName: string) => {
  const result = [];
  const days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
  //@ts-ignore
  const day = days[dayName.toLowerCase().substr(0, 3)];
  const current = new Date(start);
  current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
  while (current < end) {
    result.push(new Date(+current));
    current.setDate(current.getDate() + 7);
  }
  return result;
};

export const compactObject = (obj: any) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      newObj = { ...newObj, [key]: obj[key] };
    }
  });
  return newObj;
};

export const getTimeFormat = (is12H?: boolean): string => {
  return is12H ? 'h:mm A' : 'HH:mm';
};

export const getRussianRemainer = (n: number): number => n % 100 < 20 ? n % 100 : n % 10;

const subtractTimeMinutes = (time: string, minutes: number): string => {
  const [h, m] = time.split(':');
  const totalMinutes = parseInt(h, 10) * 60 + parseInt(m, 10) - minutes;
  return `${`0${Math.floor(totalMinutes / 60)}`.slice(-2)}:${`0${totalMinutes % 60}`.slice(-2)}`;
};

export const convertTimeTo12H = (time: string): string => {
  const [hours, minutes] = time.split(':');
  return moment().hours(+hours).minutes(+minutes).format('h:mm A');
};

export const checkTimeValidity = (time: string, locationWorkingHours: string[][], masterWorkingHours: string[][], services: IService[]): boolean => {
  const servicesDuration = getServicesDuration(services);
  let locationResult = !!locationWorkingHours.length && time >= locationWorkingHours[0][0] && time <= subtractTimeMinutes(locationWorkingHours[0][1], servicesDuration);
  if (!locationResult && locationWorkingHours.length && locationWorkingHours[1]) {
    locationResult = locationResult || (time >= locationWorkingHours[1][0] && time <= subtractTimeMinutes(locationWorkingHours[1][1], servicesDuration));
  }
  let masterResult = !!masterWorkingHours.length && time >= masterWorkingHours[0][0] && time <= subtractTimeMinutes(masterWorkingHours[0][1], servicesDuration);
  if (!masterResult && masterWorkingHours.length && masterWorkingHours[1]) {
    masterResult = masterResult || (time >= masterWorkingHours[1][0] && time <= subtractTimeMinutes(masterWorkingHours[1][1], servicesDuration));
  }
  return locationResult && masterResult;
};

export const getServicesDuration = (services: IService[]) =>
  services.reduce((duration, service) => duration += service.duration + service.pauseAfter, 0);

export const extractMasters = (location: ILocation, services: IService[]) => {
  const masters: IMaster[] = [];
  if (services.length) {
    (location.masters as IMaster[]).forEach(master => {
      if (services.every(service => (master.services as IService[]).some(masterService => masterService._id === service._id))) {
        masters.push(master);
      }
    });
  } else {
    masters.push(...location.masters as IMaster[]);
  }
  return masters;
};

const MINUTES_IN_DAY = 24 * 60;

export const calculateAvailableMinutes = (selectedTime: string, availableTime: string[]) => {
  const [hours, minutes] = selectedTime.split(':');
  const selectedMinutes = +hours * 60 + +minutes;
  let lastMinutes = selectedMinutes + RESERVATION_INTERVAL_IN_MINUTES;
  while (
    lastMinutes < MINUTES_IN_DAY &&
    availableTime.includes(moment().hours(Math.floor(lastMinutes / 60)).minutes(lastMinutes % 60).format('HH:mm'))
  ) {
    lastMinutes += RESERVATION_INTERVAL_IN_MINUTES;
  }
  return lastMinutes - selectedMinutes;
}
