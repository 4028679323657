import { AnyAction, Reducer } from 'redux';
import { AppActionTypes, AppState, WidgetScreen } from './types';

const initialState: AppState = {
  aboutUs: '',
  header: null,
  locationIds: [],
  screen: WidgetScreen.Reservation,
  screenData: null,
};

const setWidgetHeader = (state: AppState, action: AnyAction): AppState => ({ ...state, header: action.payload });

const setWidgetOptions = (state: AppState, action: AnyAction): AppState => ({
  ...state,
  aboutUs: action.payload.aboutUs,
  locationIds: action.payload.locationIds,
});

const setWidgetScreen = (state: AppState, action: AnyAction): AppState => ({ ...state, screen: action.payload });

const unsetWidgetHeader = (state: AppState): AppState => ({ ...state, header: null });

const setScreenData = (state: AppState, action: AnyAction): AppState => ({ ...state, screenData: action.payload });

const unsetScreenData = (state: AppState): AppState => ({ ...state, screenData: null });

export const appReducer: Reducer<AppState> = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.SET_WIDGET_HEADER:
      return setWidgetHeader(state, action);
    case AppActionTypes.SET_WIDGET_OPTIONS:
      return setWidgetOptions(state, action);
    case AppActionTypes.SET_WIDGET_SCREEN:
      return setWidgetScreen(state, action);
    case AppActionTypes.UNSET_WIDGET_HEADER:
      return unsetWidgetHeader(state);
    case AppActionTypes.SET_SCREEN_DATA:
      return setScreenData(state, action);
    case AppActionTypes.UNSET_SCREEN_DATA:
      return unsetScreenData(state);
    default:
      return state;
  }
};
