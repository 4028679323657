import { Avatar, Button, Col, Rate, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMaster } from '../../../../models/IUser';

export const Master = (props: MasterProps) => {
  const { t } = useTranslation();

  const handleClick = (event: any) => {
    if (!props.disabled && event.target.tagName !== 'BUTTON' && event.target.parentNode.tagName !== 'BUTTON') {
      props.onClick();
    }
  };

  const handleReviewsClick = (event: any) => {
    event.stopPropagation();
    props.onReviewsClick();
  };

  return (
    <li className={'list-item' + (props.disabled ? ' list-item--disabled' : '')} onClick={handleClick}>
      <Row>
        <div className="avatar-slot flex-shrink-0">
          {props.master.avatarUrl && (
            <Avatar
              alt={props.master.name}
              shape="square"
              size={60}
              src={props.master.avatarUrl}
            />
          )}
        </div>
        <Col flex="auto" className="break-word">
          <b className="title">{props.master.name}</b>
          <div>{props.master.specialization}</div>
        </Col>
        <Col className="flex flex-column justify-content-center flex-shrink-0">
          <Row>
            <Rate disabled defaultValue={props.master.avgRating} />
          </Row>
          <Row>
            <Button
              className="reviews-button"
              size="small"
              type="link"
              onClick={handleReviewsClick}
            >
              {t('totalReviews_interval', {
                postProcess: 'interval',
                count: (props.master.total || 0) % 10,
                total: props.master.total || 0,
              })}
            </Button>
          </Row>
        </Col>
      </Row>
      {!!props.availability.length && (
        <Row className="mt-2 flex flex-column">
          <p className="reservation-time-label">
            {t('The closest available time is')} {}
            {props.date === props.todayDate ? t('today') :
              props.date === props.tomorrowDate ? t('tomorrow') : moment(props.date).format('DD MMMM, dddd')}:
          </p>
          <div className="reservation-time flex">
            {props.availability.map(([time, formattedTime]: string[]) => (
              <div className="button-slot mr-1" key={time}>
                <Button
                  className="w-full"
                  onClick={() => props.onTimeSelect(props.master, props.date, time)}
                >
                  {formattedTime}
                </Button>
              </div>
            ))}
          </div>
        </Row>
      )}
    </li>
  );
};

interface MasterProps {
  availability: string[][];
  date: string;
  disabled: boolean;
  master: IMaster;
  todayDate: string;
  tomorrowDate: string;
  onClick: () => void;
  onReviewsClick: () => void;
  onTimeSelect: (master: IMaster, date: string, time: string) => void;
}
