import { UserActionTypes } from './types';
import { IUser, IUserInput, IUserUpdateInput } from '../../models/IUser';
import { IQuery } from '../../models/IQuery';
import { IAuthCredential } from '../../models/IAuthCredential';
import { IReservation } from '../../models/IReservation';

type Error = string;

export const createUserRequest = (user: IUserInput, locationId: string) => ({
  type: UserActionTypes.CREATE_USER_REQUEST,
  payload: { user, locationId },
});
export const createUserSuccess = (user: IUser, needConfirm: boolean = false) => ({
  type: UserActionTypes.CREATE_USER_SUCCESS,
  payload: { user, needConfirm },
});
export const cancelUserCreate = () => ({ type: UserActionTypes.CANCEL_USER_CREATE });

export const updateUserRequest = (user: IUserUpdateInput, userId: string) => ({
  type: UserActionTypes.UPDATE_USER_REQUEST,
  payload: { user, userId },
});
export const updateUserSuccess = (user: IUser) => ({
  type: UserActionTypes.UPDATE_USER_SUCCESS,
  payload: user,
});

export const setCurrentUserSuccess = (user: IUser) => ({
  type: UserActionTypes.SET_CURRENT_USER_SUCCESS,
  payload: { user },
});

export const confirmUserRequest = (confirmCode: string) => ({
  type: UserActionTypes.CONFIRM_USER_REQUEST,
  payload: confirmCode,
});

export const confirmUserSuccess = () => ({
  type: UserActionTypes.CONFIRM_USER_SUCCESS,
});

export const getCurrentUserRequest = (userId: string) => ({
  type: UserActionTypes.GET_CURRENT_USER_REQUEST,
  payload: userId,
});

export const deleteUserAccountRequest = () => ({
  type: UserActionTypes.DELETE_USER_REQUEST,
});
export const deleteUserAccountSuccess = () => ({
  type: UserActionTypes.DELETE_USER_SUCCESS,
});

export const searchUsersRequest = (locationId: string, pagination: IQuery, filterBy: any, queryData: any) => ({
  type: UserActionTypes.SEARCH_USERS_REQUEST,
  payload: {
    locationId,
    pagination,
    filterBy,
    queryData,
  },
});
export const searchUsersSuccess = (users: IUser[], total: number) => ({
  type: UserActionTypes.SEARCH_USERS_SUCCESS,
  payload: { users, total },
});

export const loginUserRequest = (credential: IAuthCredential) => ({
  type: UserActionTypes.LOGIN_USER_REQUEST,
  payload: credential,
});
export const loginUserSuccess = (user: IUser, needConfirm: boolean = false) => ({
  type: UserActionTypes.LOGIN_USER_SUCCESS,
  payload: { user, needConfirm },
});

export const onUserLoadRequest = (token: string) => ({
  type: UserActionTypes.ON_LOAD_USER_REQUEST,
  payload: token,
});

export const userReservationsRequest = (pagination: IQuery) => ({
  type: UserActionTypes.USER_RESERVATION_REQUEST,
  payload: pagination,
});
export const userReservationsSuccess = (reservations: IReservation) => ({
  type: UserActionTypes.USER_RESERVATION_SUCCESS,
  payload: reservations,
});

export const cleanReservations = () => ({
  type: UserActionTypes.CLEAN_USER_RESERVATION_SUCCESS,
});

export const userLogout = () => ({
  type: UserActionTypes.USER_LOGOUT,
});

export const forgotPasswordRequest = (value: { email: string }) => ({
  type: UserActionTypes.NEW_PASSWORD_REQUEST,
  payload: value.email,
});

export const onClientRequestFailure = (data: Error) => ({
  type: UserActionTypes.CLEAN_ERRORS,
  payload: data,
});
