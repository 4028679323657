import clsx from 'clsx';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { ILocation } from '../../../../models/ILocation';
import { IMaster } from '../../../../models/IUser';
import './Calendar.css';

export const Calendar = (props: CalendarProps) => {
  const [currentMonth, setCurrentMonth] = useState(() => moment(props.currentDate).format('YYYY MMMM'));
  const currentDate = useMemo(() => moment(props.currentDate).format('YYYY-MM-DD'), [props.currentDate]);

  const isDateAvailable = (date: Date): boolean => {
    const day = moment(date).locale('en').format('dddd').toLocaleLowerCase() as 'monday';
    return !!(props.location.workingHours[day] && (!props.master || props.master.workingHours[day])) &&
      (moment(date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') || props.todayDateAvailable);
  };

  const [allMonths, days] = useMemo(() => {
    const days: Record<string, [Date, boolean][]> = {};
    const endDate = moment(props.endDate).format('YYYY-MM-DD');
    const currentMoment = moment(props.startDate);
    while (currentMoment.format('YYYY-MM-DD') <= endDate) {
      const monthKey = currentMoment.format('YYYY MMMM');
      if (!days[monthKey]) {
        days[monthKey] = [];
      }
      const currentDate = currentMoment.toDate();
      days[monthKey].push([currentDate, isDateAvailable(currentDate)]);
      currentMoment.add(1, 'day');
    }
    return [[...new Set(Object.keys(days))], days];
  }, [props.startDate, props.endDate]);

  const months = useMemo(() => {
    const currentMonthIndex = allMonths.findIndex(month => month === currentMonth);
    return [
      currentMonthIndex > 0 && allMonths[currentMonthIndex - 1],
      allMonths[currentMonthIndex],
      currentMonthIndex < allMonths.length - 1 && allMonths[currentMonthIndex + 1],
    ];
  }, [currentMonth, allMonths]);

  const handleMonthSelect = (month: string) => setCurrentMonth(month);
  const handleDaySelect = (date: Date, available: boolean) => {
    if (available && moment(date).format('YYYY-MM-DD') !== currentDate) {
      props.onSelect(date);
    }
  };

  return (
    <div className="flex flex-column">
      <div className="reservation-calendar--months flex mb-2">
        {months.map((month, index) => (
          <div key={index} className="month-item flex align-items-center flex-grow-1">
            {!!month && <span onClick={() => handleMonthSelect(month)}>{month.split(' ')[1]}</span>}
          </div>
        ))}
      </div>
      <div className="reservation-calendar--days">
        <div style={{ width: `${days[currentMonth].length * (32 + 4) - 4}px` }}>
          {days[currentMonth].map(([date, available], index) => (
            <div
              key={index}
              className={clsx('day-item mr-1 flex flex-column',
                !available && 'day-item--disabled', moment(date).format('YYYY-MM-DD') === currentDate && 'day-item--current')}
              onClick={() => handleDaySelect(date, available)}
            >
              <span className="day-name flex align-items-center justify-content-center">{moment(date).format('dd')}</span>
              <span className="day-button flex align-items-center justify-content-center">{moment(date).format('D')}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface CalendarProps {
  location: ILocation;
  master: IMaster | null;
  todayDateAvailable: boolean;
  currentDate: Date;
  startDate: Date;
  endDate: Date;
  onSelect: (date: Date) => void;
}
