import { takeEvery, put, call } from 'redux-saga/effects';
import { MasterActionTypes } from './types';
import MasterService from '../../services/master-service';
import { deleteFeedbackSuccess, masterInfoSuccess, onMasterRequestFailure } from './actions';
import { IMasterUpdateInput } from '../../models/IUser';
import { push } from 'react-router-redux';
import { notification } from 'antd';
import i18n from 'i18next';
import { removeMasterSuccess } from '../location/actions';
import FeedbackService from '../../services/feedback-service';

const masterService = new MasterService();
const feedbackService = new FeedbackService();

function* masterInfoWorker(action: { type: string; payload: string }) {
  try {
    const response = yield masterService.getMasterInfo(action.payload);
    yield put(masterInfoSuccess(response.data.master, response.data.feedback));
  } catch (e) {
    console.log(e);
    yield put(onMasterRequestFailure(e.message));
  }
}

function* updateMasterWorker(action: {
  type: string;
  payload: {
    master: IMasterUpdateInput;
    masterId: string;
  };
}) {
  try {
    const response = yield call(masterService.updateMaster, action.payload.master, action.payload.masterId);
    yield put(masterInfoSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onMasterRequestFailure(e.message));
  }
}

function* deleteMasterWorker(action: { type: string; payload: { masterId: string; locationId: string } }) {
  try {
    yield masterService.deleteMaster(action.payload.masterId, action.payload.locationId);
    yield put(removeMasterSuccess(action.payload.masterId));
    yield put(push(`/calendar${window.location.search}`));
    notification.success({
      message: i18n.t('masterWasRemoved'),
    });
  } catch (e) {
    console.log(e);
    yield put(onMasterRequestFailure(e.message));
  }
}

function* deleteMasterFeedbackWorker(action: { type: string; payload: string }) {
  try {
    yield feedbackService.deleteFeedback(action.payload);
    yield put(deleteFeedbackSuccess(action.payload));
  } catch (e) {
    console.log(e);
    yield put(onMasterRequestFailure(e.message));
  }
}
export function* watchMaster() {
  yield takeEvery(MasterActionTypes.MASTER_INFO_REQUEST, masterInfoWorker);
  yield takeEvery(MasterActionTypes.UPDATE_MASTER_REQUEST, updateMasterWorker);
  yield takeEvery(MasterActionTypes.DELETE_FEEDBACK_REQUEST, deleteMasterFeedbackWorker);
  yield takeEvery(MasterActionTypes.DELETE_MASTER_REQUEST, deleteMasterWorker);
}

const masterWatchers: any = [watchMaster()];

export default masterWatchers;
