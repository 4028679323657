import { Button, Collapse } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from '../../../../models/ILocation';
import { IService } from '../../../../models/IService';
import { IMaster } from '../../../../models/IUser';
import { getMasterAvailability } from '../../../../services/master.service';
import { calculateAvailableMinutes, convertTimeTo12H, extractMasters, getServicesDuration } from '../../../../services/utils';

const dayParts = ['morning', 'afternoon', 'evening'];

export const TimePicker = (props: TimePickerProps) => {
  const { t } = useTranslation();

  const [availableTime, setAvailableTime] = useState<string[]>([]);
  const [reservationTime, setReservationTime] = useState<Record<string, [string, string][]>>({
    morning: [],
    afternoon: [],
    evening: [],
  });

  const convertTime = (time: string) => props.location.is12H ? convertTimeTo12H(time) : time;

  const groupReservationTime = (reservationTime: string[]) => reservationTime.reduce((groups, time) => {
    if (time < '12:00') {
      if (!groups.morning) {
        groups.morning = [];
      }
      groups.morning.push([time, convertTime(time)]);
    } else if (time >= '18:00') {
      if (!groups.evening) {
        groups.evening = [];
      }
      groups.evening.push([time, convertTime(time)]);
    } else {
      if (!groups.afternoon) {
        groups.afternoon = [];
      }
      groups.afternoon.push([time, convertTime(time)]);
    }
    return groups;
  }, {} as Record<string, [string, string][]>);

  useEffect(() => {
    if (props.master) {
      getMasterAvailability([props.master._id], props.location._id,
        moment(props.date).format('YYYY-MM-DD'), getServicesDuration(props.services))
        .then(response => {
          setAvailableTime(response.data);
          setReservationTime(groupReservationTime(response.data));
        })
        .catch(error => console.log(error));
    } else {
      const masters = extractMasters(props.location, props.services);
      if (masters.length) {
        getMasterAvailability(masters.map(master => master._id), props.location._id,
          moment(props.date).format('YYYY-MM-DD'), getServicesDuration(props.services))
          .then(response => {
            setAvailableTime(response.data);
            setReservationTime(groupReservationTime(response.data));
          })
          .catch(error => console.log(error));
      }
    }
  }, [props.date, props.location, props.master, props.services]);

  const handleTimeSelect = (time: string) => {
    const availableMinutes = calculateAvailableMinutes(time, availableTime);
    props.onSelect(time, availableMinutes);
  };

  return (
    <Collapse className="reservation-time" defaultActiveKey={dayParts} ghost>
      {dayParts.map(dayPart => reservationTime[dayPart] && (
        <Collapse.Panel header={t(capitalize(dayPart))} key={dayPart}>
          <div className="flex flex-wrap m--1">
            {reservationTime[dayPart].map(([time, formattedTime]) => (
              <div key={time} className="w-25 p-1">
                <Button className="w-full" onClick={() => handleTimeSelect(time)}>
                  {formattedTime}
                </Button>
              </div>
            ))}
          </div>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

interface TimePickerProps {
  location: ILocation;
  master: IMaster | null;
  date: Date;
  services: IService[];
  onSelect: (time: string, availableMinutes: number) => void;
}
