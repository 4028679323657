import RequestService from './request-service';
import { AxiosResponse } from 'axios';

export default class SubscriptionService extends RequestService {
  public getUserSubscriptions = (locationId?: string | any): Promise<AxiosResponse> => {
    let apiRoute = `subscription/user-subscriptions`;
    if (locationId) {
      apiRoute = `subscription/user-subscriptions?locationId=${locationId}`;
    }
    return this.instance.get(apiRoute);
  };

  public updateUserSubscriptions = ({
    plan_id,
    subscription_id,
  }: {
    plan_id: string;
    subscription_id: string;
  }): Promise<AxiosResponse> => {
    return this.instance.post('subscription/update-user-subscription', {
      plan_id,
      subscription_id,
    });
  };
}
