import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import { selectAboutUs } from '../../store/app/selectors';
import './AboutUs.css';

export const AboutUs: React.FC = () => {
  const aboutUs = useSelector(selectAboutUs);
  const { location } = useSelector((state: IApplicationState) => ({
    location: state.location.locations[0],
  }));

  return (
    <div className="about-us">
      {location?.avatarUrl && (
        <div className="mb-3 flex justify-content-center avatar">
          <img alt={location.name} src={location.avatarUrl} />
        </div>
      )}
      <p>{aboutUs}</p>
      <p>
        <a
          href={`https://maps.google.com/?q=${location.coordinate.lat},${location.coordinate.lng}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {location.address}
        </a>
      </p>
      {!!location.phones?.length && (
        <p><PhoneOutlined /> {location.phones.map((phone, index) => (
          <React.Fragment key={index}>
            <a href={`tel:+${phone.countryCode + phone.phone}`}>+{phone.countryCode + phone.phone}</a>
            {index < location.phones.length - 1 ? ', ' : ''}
          </React.Fragment>
        ))}</p>
      )}
      {!!location.emails?.length && (
        <p><MailOutlined /> {location.emails.map((email, index) => (
          <React.Fragment key={index}>
            <a href={`mailto:${email.email}`}>{email.email}</a>
            {index < location.emails.length - 1 ? ', ' : ''}
          </React.Fragment>
        ))}</p>
      )}
    </div>
  );
};
