import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RESERVATION_INTERVAL_IN_MINUTES } from '../../../const';
import { ILocation } from '../../../models/ILocation';
import { IService } from '../../../models/IService';
import { IMaster } from '../../../models/IUser';
import { getMasterAvailability } from '../../../services/master.service';
import { checkTimeValidity, extractMasters, getServicesDuration } from '../../../services/utils';
import { Header } from '../Header/Header';
import { Calendar } from './Calendar/Calendar';
import { TimePicker } from './TimePicker/TimePicker';

export const Timesheet = (props: TimesheetProps) => {
  const { t } = useTranslation();

  const [todayDateAvailable, setTodayDateAvailability] = useState<boolean>(true);
  const [currentDate, setCurrentDate] = useState<Date | null>(props.date ? moment(props.date).toDate() : null);
  const [startDate] = useState<Date>(new Date());
  const endDate = useMemo<Date>(() => new Date(startDate.getTime() + props.location.notAfter * 24 * 60 * 60 * 1e3), [props.location]);

  useEffect(() => {
    if (!props.date) {
      (async () => {
        const todayDateAvailable = await isThereAtLeastOneAvailableMaster(startDate);
        setTodayDateAvailability(todayDateAvailable);
        let date = startDate;
        let days = props.location.notAfter;
        while (days--) {
          if (await isDateAvailable(date, todayDateAvailable)) {
            setCurrentDate(date);
            return;
          }
          date = new Date(date.getTime() + 24 * 60 * 60 * 1e3);
        }
        setCurrentDate(startDate);
      })();
    }
  }, [props.date, props.location]);

  const isDateAvailable = useCallback(async (date: Date, todayDateAvailable: boolean): Promise<boolean> => {
    const day = moment(date).locale('en').format('dddd').toLocaleLowerCase() as 'monday';
    if (!props.master) {
      const locationHours = props.location.workingHours[day];
      if (locationHours) {
        if (date.getTime() === startDate.getTime()) {
          const [locationEndHours, locationEndMinutes] =
            locationHours[locationHours.length - 1].split('-')[1].split(':');
          const locationEndMoment = moment().hours(+locationEndHours)
            .minutes(+locationEndMinutes - RESERVATION_INTERVAL_IN_MINUTES)
            .seconds(0).millisecond(0);
          return new Date().getTime() < locationEndMoment.toDate().getTime() && todayDateAvailable;
        }
        return true;
      }
      return false;
    }
    try {
      const response = await getMasterAvailability([props.master!._id], props.location._id,
        moment(date).format('YYYY-MM-DD'), getServicesDuration(props.services));
      const availability = response.data.filter(time => checkTimeValidity(time,
        (props.location.workingHours[day] || []).map(period => period.split('-')),
        (props.master!.workingHours[day] || []).map(period => period.split('-')),
        props.services));
      return !!availability.length;
    } catch (error) {
      console.error(error);
    }
    return false;
  }, [props.location, props.master, props.services]);

  const isThereAtLeastOneAvailableMaster = useCallback(async (date: Date): Promise<boolean> => {
    const masters = extractMasters(props.location, props.services);
    if (masters.length) {
      try {
        const response = await getMasterAvailability(masters.map(master => master._id), props.location._id,
          moment(date).format('YYYY-MM-DD'), getServicesDuration(props.services));
        return !!response.data.length;
      } catch (error) {
        console.log(error);
      }
    }
    return false;
  }, [props.location, props.services]);

  const handleDateSelect = (date: Date) => setCurrentDate(date);

  const handleTimeSelect = (time: string, availableMinutes: number) =>
    props.onSelect(moment(currentDate).format('YYYY-MM-DD'), time, availableMinutes);

  return (
    <div>
      <Header
        title={<>{t('Choose')} <b>{t('[Slide] reservationTime')}</b></>}
        onBack={props.onBack}
      />
      {!!currentDate && (
        <>
          <Calendar
            location={props.location}
            master={props.master}
            todayDateAvailable={todayDateAvailable}
            currentDate={currentDate}
            startDate={startDate}
            endDate={endDate}
            onSelect={handleDateSelect}
          />
          <TimePicker
            location={props.location}
            master={props.master}
            services={props.services}
            date={currentDate}
            onSelect={handleTimeSelect}
          />
        </>
      )}
    </div>
  );
};

interface TimesheetProps {
  date: string | null;
  location: ILocation;
  master: IMaster | null;
  services: IService[];
  onBack: () => void;
  onSelect: (date: string, time: string, availableMinutes: number) => void;
}
