import { Reducer } from 'redux';
import { UserActionTypes, UserState } from './types';
import { replaceInArray } from '../../services/utils';

export const initialState: UserState = {
  users: [],
  reservations: [],
  currentUser: null,
  total: 0,
  error: null,
  loading: false,
  needConfirm: false,
};
//@ts-ignore
const reducer: Reducer<UserState> = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.GET_CURRENT_USER_REQUEST:
    case UserActionTypes.CONFIRM_USER_REQUEST:
    case UserActionTypes.UPDATE_USER_REQUEST:
    case UserActionTypes.DELETE_USER_REQUEST:
    case UserActionTypes.ON_LOAD_USER_REQUEST:
    case UserActionTypes.USER_RESERVATION_REQUEST:
    case UserActionTypes.LOGIN_USER_REQUEST:
    case UserActionTypes.SEARCH_USERS_REQUEST:
    case UserActionTypes.CREATE_USER_REQUEST: {
      return { ...state, loading: true };
    }
    case UserActionTypes.DELETE_USER_SUCCESS:
    case UserActionTypes.USER_LOGOUT: {
      return {
        users: [],
        reservations: [],
        currentUser: null,
        total: 0,
        error: null,
        loading: false,
      };
    }

    case UserActionTypes.CONFIRM_USER_SUCCESS: {
      return { ...state, loading: false, needConfirm: false };
    }
    case UserActionTypes.CLEAN_USER_RESERVATION_SUCCESS: {
      return { ...state, loading: false, reservations: [] };
    }
    case UserActionTypes.USER_RESERVATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        reservations: [...state.reservations, ...action.payload],
      };
    }
    case UserActionTypes.CREATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: [action.payload, ...state.users],
        total: state.total + 1,
      };
    }
    case UserActionTypes.UPDATE_USER_SUCCESS: {
      const users = replaceInArray(state.users, action.payload._id, action.payload);
      return { ...state, loading: false, users: [...users] };
    }
    case UserActionTypes.SEARCH_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        total: action.payload.total,
      };
    }
    case UserActionTypes.SET_CURRENT_USER_SUCCESS:
    case UserActionTypes.LOGIN_USER_SUCCESS:
    case UserActionTypes.ON_LOAD_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentUser: action.payload.user,
        needConfirm: action.payload.needConfirm,
      };
    }
    case UserActionTypes.CANCEL_USER_CREATE:
      return {
        ...state,
        currentUser: null,
        needConfirm: false,
      };
    case UserActionTypes.USER_REQUEST_FAILURE: {
      return { ...state, loading: false, error: action.payload };
    }
    case UserActionTypes.CLEAN_ERRORS: {
      return { ...state, loading: false, error: null };
    }
    default: {
      return state;
    }
  }
};

export { reducer as UserReducer };
