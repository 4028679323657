import { IDiscount } from '../../models/IDiscount';

export interface DiscountState {
  readonly loading: boolean;
  readonly discounts: IDiscount[];
  readonly total: number;
  readonly error: string | null;
}

export enum DiscountActionTypes {
  CREATE_DISCOUNT_REQUEST = '@@discount/CREATE_DISCOUNT_REQUEST',
  CREATE_DISCOUNT_SUCCESS = '@@discount/CREATE_DISCOUNT_SUCCESS',

  DELETE_DISCOUNT_REQUEST = '@@discount/DELETE_DISCOUNT_REQUEST',
  DELETE_DISCOUNT_SUCCESS = '@@discount/DELETE_DISCOUNT_SUCCESS',

  UPDATE_DISCOUNT_REQUEST = '@@discount/UPDATE_DISCOUNT_REQUEST',
  UPDATE_DISCOUNT_SUCCESS = '@@discount/UPDATE_DISCOUNT_SUCCESS',

  GET_DISCOUNT_REQUEST = '@@discount/GET_DISCOUNT_REQUEST',
  GET_DISCOUNT_SUCCESS = '@@discount/GET_DISCOUNT_SUCCESS',

  CLEAN_ERRORS = '@@discount/CLEAN_ERRORS',
}
