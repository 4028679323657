import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AlarmIcon } from '../../../icons/AlarmIcon';
import { CalendarIcon } from '../../../icons/CalendarIcon';
import { InfoIcon } from '../../../icons/InfoIcon';
import { KeyCardIcon } from '../../../icons/KeyCardIcon';
import { setWidgetScreen } from '../../../store/app/actions';
import { selectWidgetScreen } from '../../../store/app/selectors';
import { WidgetScreen } from '../../../store/app/types';
import { MenuItem } from './MenuItem';
import './Toolbar.css';

export const Toolbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const screen = useSelector(selectWidgetScreen);

  const handleClick = (screen: WidgetScreen) => dispatch(setWidgetScreen(screen));

  return (
    <nav className="widget-toolbar">
      <ul>
        <MenuItem
          active={screen === WidgetScreen.Reservation}
          Icon={() => <CalendarIcon />}
          title={t('Reservation')}
          onClick={() => handleClick(WidgetScreen.Reservation)}
        />
        <MenuItem
          active={screen === WidgetScreen.MyBookings}
          Icon={() => <AlarmIcon />}
          title={t('My bookings')}
          onClick={() => handleClick(WidgetScreen.MyBookings)}
        />
        <MenuItem
          active={screen === WidgetScreen.Profile}
          Icon={() => <KeyCardIcon />}
          title={t('Profile')}
          onClick={() => handleClick(WidgetScreen.Profile)}
        />
        <MenuItem
          active={screen === WidgetScreen.AboutUs}
          Icon={() => <InfoIcon />}
          title={t('About us')}
          onClick={() => handleClick(WidgetScreen.AboutUs)}
        />
      </ul>
    </nav>
  );
};
