import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Drawer, Empty, Form, List, Spin, Tabs, Tag, Typography } from 'antd';
import { IApplicationState } from '../../models/IApplicationState';
import UserAuth from '../UserAuth/UserAuth';
import { cleanReservations, userReservationsRequest } from '../../store/user/actions';
import { IReservation, IReservationInput } from '../../models/IReservation';
import { Loader } from '../Loader/Loader';
import { createReservationRequest, updateReservationRequest } from '../../store/reservation/actions';
import { Reservations } from './Reservations/Reservations';
import { ILocation } from '../../models/ILocation';
import { fetchLocations } from '../../services/location.service';
import moment from 'moment';
import { setScreenData, setWidgetScreen } from '../../store/app/actions';
import { WidgetScreen } from '../../store/app/types';
import { IService } from '../../models/IService';
import { IMaster } from '../../models/IUser';

const Wrapper = styled.div``;

const RightRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ListItem = styled(List.Item)`
  display: flex;
  align-items: end;
`;

const ReservationUpdatedData = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
`;

export const Bookings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [repeatForm] = Form.useForm();

  const { auth, reservations, loading, creatingReservation } = useSelector((state: IApplicationState) => ({
    auth: !!state.user.currentUser,
    reservations: state.user.reservations,
    loading: state.user.loading,
    creatingReservation: state.reservation.loading,
    location: state.location.currentLocation,
  }));
  const [repeatModalVisibility, setRepeatModalVisibility] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null as any);

  const { doneReservations, activeReservations } = useMemo(() => {
    return {
      activeReservations: reservations
        .filter(v => moment(v.reservedAt).add(v.duration, 'minutes').toDate().getTime() > new Date().getTime())
        .sort((a, b) => a.reservedAt < b.reservedAt ? -1 : a.reservedAt > b.reservedAt ? 1 : 0),
      doneReservations: reservations
        .filter(v => moment(v.reservedAt).add(v.duration, 'minutes').toDate().getTime() <= new Date().getTime())
        .sort((a, b) => a.reservedAt > b.reservedAt ? -1 : a.reservedAt < b.reservedAt ? 1 : 0),
    };
  }, [reservations]);

  const [locations, setLocations] = useState<ILocation[]>([]);
  useEffect(() => {
    if (reservations.length) {
      fetchLocations([...new Set(reservations.map(reservation => reservation.locationId))])
        .then(response => setLocations(response.data))
        .catch(console.error);
    }
  }, [reservations, reservations.length]);

  useEffect(() => {
    if (!creatingReservation) {
      repeatForm.resetFields();
      setRepeatModalVisibility(false);
    }
    // eslint-disable-next-line
  }, [creatingReservation]);

  useEffect(() => {
    if (auth) dispatch(userReservationsRequest({ limit: 10, offset: 0 }));
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    return (): void => {
      dispatch(cleanReservations());
    };
    // eslint-disable-next-line
  }, []);

  if (!auth) return <UserAuth />;

  const handleReservationCancel = (reservation: any) => {
    dispatch(updateReservationRequest({ status: 'canceled' }, reservation._id, 'client', reservation.locationId));
  };

  const handleReservationRepeat = (reservation: IReservation) => {
    // setRepeatModalVisibility(true);
    // setSelectedReservation(reservation);
    dispatch(setScreenData({
      locationId: reservation.locationId,
      serviceId: (reservation.service as IService)._id,
      masterId: (reservation.master as IMaster)._id,
    }));
    dispatch(setWidgetScreen(WidgetScreen.Reservation));
  };

  const handleReservationRepeatHandler = (values: any) => {
    const newReservationData = {
      client: selectedReservation.client,
      comment: selectedReservation.comment,
      discounts: [],
      duration: selectedReservation.duration,
      locationAdmin: selectedReservation.locationAdmin,
      master: selectedReservation.master._id,
      pauseAfter: selectedReservation.pauseAfter,
      price: selectedReservation.price,
      reservedAt: values.reservedAt.toDate(),
      service: selectedReservation.service._id,
    };
    dispatch(
      createReservationRequest(selectedReservation.locationId as string, newReservationData as IReservationInput)
    );
  };

  return (
    <Wrapper>
      {loading ? (
        <>
          <Form form={repeatForm} />
          <Loader />
        </>
      ) : (
        <>
          <div>
            {reservations.length === 0 ? (
              <Empty />
            ) : (
              <Tabs defaultValue="1">
                <Tabs.TabPane
                  tab={
                    <span>
                      {t('Active')} <Tag>{activeReservations.length}</Tag>
                    </span>
                  }
                  key="1"
                >
                  {locations.length && activeReservations.length ? (
                    <Reservations
                      locations={locations}
                      reservations={activeReservations}
                      onReservationCancel={handleReservationCancel}
                      onReservationRepeat={handleReservationRepeat}
                    />
                  ) : (
                    <Empty
                      description={t('You have no upcoming bookings')}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <span>
                      {t('History')} <Tag>{doneReservations.length}</Tag>
                    </span>
                  }
                  key="2"
                >
                  {!!locations.length && <Reservations
                    locations={locations}
                    reservations={doneReservations}
                    onReservationCancel={handleReservationCancel}
                    onReservationRepeat={handleReservationRepeat}
                  />}
                </Tabs.TabPane>
              </Tabs>
            )}
          </div>

          <Drawer
            title={t('handleReservationRepeat')}
            placement="right"
            closable={true}
            width={350}
            visible={repeatModalVisibility}
            onClose={() => setRepeatModalVisibility(false)}
          >
            <Spin spinning={creatingReservation}>
              {selectedReservation ? (
                <div style={{ margin: 16 }}>
                  <List size="small" header={null} footer={null}>
                    <ListItem>
                      <Typography.Text>{t('Master')}</Typography.Text>
                      <ReservationUpdatedData>
                        <Typography.Text>{selectedReservation.master.name}</Typography.Text>
                      </ReservationUpdatedData>
                    </ListItem>

                    <ListItem>
                      <Typography.Text>{t('Service')}</Typography.Text>
                      <ReservationUpdatedData>
                        <Typography.Text>{selectedReservation.service.name}</Typography.Text>
                      </ReservationUpdatedData>
                    </ListItem>
                  </List>

                  <Form form={repeatForm} onFinish={handleReservationRepeatHandler} layout={'vertical'}>
                    <Form.Item
                      rules={[{ required: true, message: t('Required field') }]}
                      name="reservedAt"
                      label={t('Date of reservation')}
                    >
                      <DatePicker
                        format={`DD MMMM [${t('at')}] HH:mm`}
                        minuteStep={1}
                        showTime={{ format: 'HH:mm' }}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>

                    <RightRow>
                      <Button loading={loading} htmlType="submit" style={{ margin: '0 8px' }} type="primary">
                        {t('Create')}
                      </Button>
                    </RightRow>
                  </Form>
                </div>
              ) : <Form form={repeatForm} />}
            </Spin>
          </Drawer>
        </>
      )}
    </Wrapper>
  );
};
