import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from '../../../models/ILocation';
import { IService } from '../../../models/IService';
import { IMaster } from '../../../models/IUser';
import { getMasterAvailability as fetchMasterAvailability } from '../../../services/master.service';
import { calculateAvailableMinutes, checkTimeValidity, convertTimeTo12H, extractMasters, getServicesDuration } from '../../../services/utils';
import { Header } from '../Header/Header';
import { Master } from './Master/Master';
import './Masters.css';

export const Masters = (props: MastersProps) => {
  const { t } = useTranslation();

  const [availableTime, setAvailableTime] = useState<string[]>([]);
  const [earliestAvailability, setEarliestAvailability] = useState<Record<string, { date: string, availability: string[][] }>>({});
  const [mastersAvailability, setMastersAvailability] = useState<Record<string, boolean>>({});
  const tomorrowDate = useMemo(() => moment().add(1, 'day').format('YYYY-MM-DD'), []);
  const todayDate = useMemo(() => moment().format('YYYY-MM-DD'), []);

  const masters = useMemo(() => props.location ? extractMasters(props.location, props.services) : [], [props.location, props.services]);

  const isValidTime = useCallback((time: string, locationWorkingHours: string[][], masterWorkingHours: string[][]): boolean =>
    checkTimeValidity(time, locationWorkingHours, masterWorkingHours, props.services), [props.services]);

  const is12H = props.location?.is12H;

  const getMasterAvailability = useCallback(async (master: IMaster, date: string): Promise<void> => {
    setEarliestAvailability(state => ({ ...state, [master._id]: { date, availability: [] }}));
    try {
      const response = await fetchMasterAvailability([master._id], props.location._id, date, getServicesDuration(props.services));
      if (response.data.length) {
        const day = moment(date).locale('en').format('dddd').toLocaleLowerCase() as 'monday';
        const locationWorkingHours = props.location ? (props.location.workingHours[day] || []).map(period => period.split('-')) : [];
        const masterWorkingHours = (master.workingHours[day] || []).map(period => period.split('-'));
        const validTime = response.data.filter(time => isValidTime(time, locationWorkingHours, masterWorkingHours));
        if (validTime.length) {
          setAvailableTime(validTime);
          if (!props.time || validTime.includes(props.time)) {
            setMastersAvailability(state => ({ ...state, [master._id]: true }));
          }
          if (!props.date && !props.time) {
            setEarliestAvailability(state => ({
              ...state,
              [master._id]: {
                date,
                availability: validTime
                  .slice(0, validTime.length > 4 ? 4 : validTime.length)
                  .map(time => [time, is12H ? convertTimeTo12H(time) : time]),
              },
            }));
          }
        } else if (date !== props.date) {
          await getMasterAvailability(master, moment(date).add(1, 'day').format('YYYY-MM-DD'));
        }
      } else if (date !== props.date && date < moment(todayDate).add(7, 'days').format('YYYY-MM-DD')) {
        await getMasterAvailability(master, moment(date).add(1, 'day').format('YYYY-MM-DD'));
      }
    } catch (error) {
      console.error(error);
    }
  }, [props.location, props.services, props.date, props.time, is12H, todayDate, isValidTime]);

  useEffect(() => {
    if (masters.length) {
      Promise.all(masters.map(master => getMasterAvailability(master, props.date || todayDate)));
    }
  }, [masters, todayDate, getMasterAvailability]);

  const handleMasterTimeSelect = (master: IMaster, date: string, time: string) => {
    const availableMinutes = calculateAvailableMinutes(time, availableTime);
    props.onTimeSelect(master, date, time, availableMinutes);
  };

  return (
    <div>
      <Header
        title={<>{t('Choose')} <b>{t('[Slide] master')}</b></>}
        onBack={props.onBack}
      />
      {masters.length ? (
        <ul className="skeedee-list reservation-masters">
          {masters.sort(master => mastersAvailability[master._id] ? -1 : 1).map((master, index) => (
            <Master
              key={index}
              availability={earliestAvailability[master._id]?.availability || []}
              date={earliestAvailability[master._id]?.date || todayDate}
              disabled={!mastersAvailability[master._id]}
              master={master}
              todayDate={todayDate}
              tomorrowDate={tomorrowDate}
              onClick={() => props.onSelect(master)}
              onReviewsClick={() => props.onReviewsClick(master)}
              onTimeSelect={handleMasterTimeSelect}
            />
          ))}
        </ul>
      ) : <h1>{t('Unfortunately, now there is no one who could perform this service.')}</h1>}
    </div>
  );
};

interface MastersProps {
  location: ILocation;
  date: string | null;
  time: string | null;
  services: IService[];
  onBack: () => void;
  onSelect: (master: IMaster) => void;
  onReviewsClick: (master: IMaster) => void;
  onTimeSelect: (master: IMaster, date: string, time: string, availableMinutes: number) => void;
}
