import { takeEvery, put, select } from 'redux-saga/effects';
import { ReservationActionTypes } from './types';
import {
  createGroupReservationSuccess,
  createReservationSuccess,
  deleteReservationSuccess,
  getGroupReservationSuccess,
  getReservationSuccess,
  onReservationRequestFailure,
  reservationSuccess,
  updateGroupReservationSuccess,
  updateReservationSuccess,
} from './actions';
import ReservationsService from '../../services/reservations-service';
import {
  IGroupReservationInput,
  IGroupReservationUpdateInput,
  IReservationInput,
  IReservationUpdateInput,
} from '../../models/IReservation';
import { IApplicationState } from '../../models/IApplicationState';
import { push } from 'react-router-redux';
import { cleanReservations, searchUsersSuccess, userReservationsRequest } from '../user/actions';
import { notification } from 'antd';
import i18n from '../../i18n';

const reservationsService = new ReservationsService();

function* reservationRequestWorker(action: { type: string; payload: { locationId: string; date: string } }) {
  try {
    const response = yield reservationsService.getLocationReservations(action.payload.locationId, action.payload.date);
    yield put(reservationSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onReservationRequestFailure(e.message));
  }
}

function* createReservationRequestWorker(action: {
  type: string;
  payload: { reservation: IReservationInput; locationId: string };
}) {
  try {
    const response = yield reservationsService.createReservation(action.payload.reservation, action.payload.locationId);
    yield put(createReservationSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onReservationRequestFailure(e.message));
  }
}

function* updateReservationRequestWorker(action: {
  type: string;
  payload: {
    reservation: IReservationUpdateInput;
    reservationId: string;
    masterId: string;
    locationId?: string;
  };
}) {
  try {
    const locationId = yield select((state: IApplicationState) => state.location.currentLocation?._id);

    const response = yield reservationsService.updateReservation(
      action.payload.reservation,
      action.payload.reservationId,
      locationId ? locationId : (action.payload.locationId as string),
      action.payload.masterId
    );

    yield put(updateReservationSuccess(response.data));

    if (action.payload.locationId) {
      notification.success({
        message: `${i18n.t('Reservation canceled')}`,
        duration: 5,
      });
      yield put(cleanReservations());
      yield put(userReservationsRequest({ limit: 10, offset: 0 }));
    }
  } catch (e) {
    console.log(e);
    yield put(onReservationRequestFailure(e.message));
  }
}

function* getReservationRequestWorker(action: { type: string; payload: string }) {
  try {
    const response = yield reservationsService.getReservation(action.payload);
    yield put(getReservationSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onReservationRequestFailure(e.message));
  }
}

function* deleteReservationRequestWorker(action: { type: string; payload: string }) {
  try {
    yield reservationsService.deleteReservation(action.payload);
    yield put(deleteReservationSuccess(action.payload));
  } catch (e) {
    console.log(e);
    yield put(onReservationRequestFailure(e.message));
  }
}

function* createGroupReservationRequestWorker(action: {
  type: string;
  payload: { reservation: IGroupReservationInput; locationId: string };
}) {
  try {
    const response = yield reservationsService.createGroupReservation(
      action.payload.reservation,
      action.payload.locationId
    );
    yield put(createGroupReservationSuccess(response.data));
    yield put(push(`/calendar${window.location.search}`));
  } catch (e) {
    console.log(e);
    yield put(onReservationRequestFailure(e.message));
  }
}

function* getGroupReservationRequestWorker(action: { type: string; payload: string }) {
  try {
    const response = yield reservationsService.getGroupReservation(action.payload);
    yield put(
      searchUsersSuccess(
        response.data.clients.map((c: any) => c.client),
        response.data.clients.length
      )
    );
    yield put(getGroupReservationSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onReservationRequestFailure(e.message));
  }
}

function* deleteGroupReservationRequestWorker(action: { type: string; payload: string }) {
  try {
    yield reservationsService.deleteGroupReservation(action.payload);
    yield put(deleteReservationSuccess(action.payload));
    yield put(push(`/calendar${window.location.search}`));
  } catch (e) {
    console.log(e);
    yield put(onReservationRequestFailure(e.message));
  }
}

function* updateGroupReservationRequestWorker(action: {
  type: string;
  payload: { reservation: IGroupReservationUpdateInput; reservationId: string };
}) {
  try {
    const locationId = yield select((state: IApplicationState) => state.location.currentLocation?._id);

    if (action.payload.reservation.clients) {
      action.payload.reservation.clients = action.payload.reservation.clients.map((c) => ({ client: c }));
    }
    const response = yield reservationsService.updateGroupReservation(
      action.payload.reservation,
      action.payload.reservationId,
      locationId as string
    );
    yield put(updateGroupReservationSuccess(response.data));
    yield put(push(`/calendar${window.location.search}`));
  } catch (e) {
    console.log(e);
    yield put(onReservationRequestFailure(e.message));
  }
}

export function* watchReservation() {
  yield takeEvery(ReservationActionTypes.RESERVATION_REQUEST, reservationRequestWorker);
  yield takeEvery(ReservationActionTypes.CREATE_RESERVATION_REQUEST, createReservationRequestWorker);
  yield takeEvery(ReservationActionTypes.UPDATE_RESERVATION_REQUEST, updateReservationRequestWorker);
  yield takeEvery(ReservationActionTypes.GET_RESERVATION_REQUEST, getReservationRequestWorker);
  yield takeEvery(ReservationActionTypes.DELETE_RESERVATION_REQUEST, deleteReservationRequestWorker);
  yield takeEvery(ReservationActionTypes.CREATE_GROUP_RESERVATION_REQUEST, createGroupReservationRequestWorker);
  yield takeEvery(ReservationActionTypes.GET_GROUP_RESERVATION_REQUEST, getGroupReservationRequestWorker);
  yield takeEvery(ReservationActionTypes.DELETE_GROUP_RESERVATION_REQUEST, deleteGroupReservationRequestWorker);
  yield takeEvery(ReservationActionTypes.UPDATE_GROUP_RESERVATION_REQUEST, updateGroupReservationRequestWorker);
}

const reservationWatchers: any = [watchReservation()];

export default reservationWatchers;
