import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../models/IApplicationState';
import {
  cancelUserCreate,
  confirmUserRequest,
  createUserRequest,
  forgotPasswordRequest,
  loginUserRequest,
} from '../../store/user/actions';
import { Loader } from '../Loader/Loader';
import { selectLocationIds } from '../../store/app/selectors';
import { CountriesFormItem } from '../CountriesFormItem/CountriesFormItem';

const UserAuth: React.FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const locationIds = useSelector(selectLocationIds);
  const [signUpForm] = Form.useForm();

  const { loading, needConfirm } = useSelector((state: IApplicationState) => ({
    loading: state.user.loading,
    user: state.user.currentUser,
    needConfirm: state.user.needConfirm,
  }));

  const handleUserConfirm = ({ confirmCode }: any) => void dispatch(confirmUserRequest(confirmCode));
  const handleSignUp = (values: any) => {
    dispatch(createUserRequest(values, locationIds[0]));
    signUpForm.resetFields(['password']);
  };
  const handleSignIn = (values: any) => void dispatch(loginUserRequest(values));
  const handlePasswordChangeRequest = (values: any) => void dispatch(forgotPasswordRequest(values));

  const handleBackClick = () => dispatch(cancelUserCreate());

  if (loading) {
    return (
      <>
        <Loader />
        <Form form={signUpForm} />
      </>
    );
  }

  if (needConfirm) {
    return (
      <>
        <Form layout="vertical" onFinish={handleUserConfirm}>
          <Form.Item
            label={t('Confirmation code')}
            name="confirmCode"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button type="primary" htmlType="submit" style={{ borderRadius: 10 }}>
              {t('Confirm')}
            </Button>
            <Button type="dashed" style={{ borderRadius: 10, marginLeft: 8 }} onClick={handleBackClick}>
              {t('Back')}
            </Button>
          </Form.Item>
        </Form>
        <Form form={signUpForm} />
      </>
    );
  }

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('Registration')} key="sign-up">
          <Form form={signUpForm} layout="vertical" onFinish={handleSignUp}>
            <Form.Item name="name" label={t('Name')} rules={[{ required: true, message: t('Required field') }]}>
              <Input />
            </Form.Item>

            <CountriesFormItem />

            <Form.Item
              name="email"
              label={t('E-mail')}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: t('Empty field or wrong data'),
                },
              ]}
            >
              <Input placeholder={t('E-mail')} />
            </Form.Item>

            <Form.Item
              label={t('Password')}
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ borderRadius: '10px' }}>
                {t('Create')}
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('Sign in')} key="login">
          <Form layout="vertical" onFinish={handleSignIn}>
            {/* <CountriesFormItem /> */}

            <Form.Item
              name="email"
              label={t('E-mail')}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: t('Empty field or wrong data'),
                },
              ]}
            >
              <Input placeholder={t('E-mail')} />
            </Form.Item>

            <Form.Item
              label={t('Password')}
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button type="primary" htmlType="submit" style={{ borderRadius: '10px' }}>
                {t('Sign in')}
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('Forgot password')} key="forgotPassword">
          <Form layout="vertical" onFinish={handlePasswordChangeRequest}>
            <Form.Item
              name="email"
              label={t('E-mail')}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: t('Empty field or wrong data'),
                },
              ]}
            >
              <Input placeholder={t('E-mail')} />
            </Form.Item>

            {/* <CountriesFormItem /> */}

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ borderRadius: '10px' }}>
                {t('Submit new password')}
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default UserAuth;
