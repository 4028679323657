export interface AppState {
  aboutUs: string;
  header: WidgetHeader | null;
  locationIds: string[];
  screen: WidgetScreen;
  screenData: Record<string, any> | null;
}

interface WidgetHeader {
  title: string;
  subtitle?: string;
}

export enum WidgetScreen {
  AboutUs,
  MyBookings,
  Profile,
  Reservation,
}

export enum AppActionTypes {
  SET_WIDGET_HEADER = '@@app/SET_WIDGET_HEADER',
  SET_WIDGET_OPTIONS = '@@app/SET_WIDGET_OPTIONS',
  SET_WIDGET_SCREEN = '@@app/SET_WIDGET_SCREEN',
  UNSET_WIDGET_HEADER = '@@app/UNSET_WIDGET_HEADER',
  SET_SCREEN_DATA = '@@app/SET_SCREEN_DATA',
  UNSET_SCREEN_DATA = '@@app/UNSET_SCREEN_DATA',
}
