import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
//@ts-ignore
import ukMoment from 'moment/locale/uk';
//@ts-ignore
import enMoment from 'moment/locale/en-gb';
//@ts-ignore
import itMoment from 'moment/locale/it';
//@ts-ignore
import ruMoment from 'moment/locale/ru';
//@ts-ignore
import plMoment from 'moment/locale/pl';

import { APP_LANG, languages } from './const';

export const momentLangSetup = {
  uk: ukMoment,
  ru: ruMoment,
  it: itMoment,
  pl: plMoment,
  en: enMoment,
};

const userLang = navigator.language || (navigator as any).userLanguage;

let appLang = userLang.includes('en') ? 'en' : userLang;
const memoLang = localStorage.getItem(APP_LANG);

if (memoLang && memoLang !== 'undefined') {
  appLang = memoLang;
} else {
  appLang = 'en';
}

if (appLang.includes('-')) {
  const [lang] = userLang.split('-');

  if (languages.includes(lang)) {
    appLang = lang;
    //@ts-ignore
    moment.locale(appLang, momentLangSetup[appLang]);
  }
} else {
  if (languages.includes(appLang)) {
    //@ts-ignore
    moment.locale(appLang, momentLangSetup[appLang]);
  }
}

localStorage.setItem(APP_LANG, appLang as string);
i18n
  .use(initReactI18next)
  .use(Backend)
  .use(intervalPlural)
  .init({
    lng: appLang,
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
    } as object,
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
  });

export default i18n;
