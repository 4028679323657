import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { history } from './store';
import { APP_AUTH_DATA_TOKEN } from './const';
import { noToken, onLoadRequest } from './store/auth/actions';
import { Widget } from './components/Widget/Widget';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem(APP_AUTH_DATA_TOKEN);
    if (token) {
      dispatch(onLoadRequest(token));
    } else {
      dispatch(noToken());
    }
  }, [dispatch]);

  return (
    <ConnectedRouter history={history}>
      <Widget />
    </ConnectedRouter>
  );
}

export default App;
