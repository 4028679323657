import { IService } from '../../models/IService';

export interface ServiceState {
  readonly loading: boolean;
  readonly services: IService[];
  readonly total: number;
  readonly error: string | null;
}

export enum ServiceActionTypes {
  ADD_SERVICE_REQUEST = '@@service/ADD_SERVICE_REQUEST',
  ADD_SERVICE_SUCCESS = '@@service/ADD_SERVICE_SUCCESS',

  GET_SERVICES_SUCCESS = '@@service/GET_SERVICES_SUCCESS',
  GET_SERVICES_REQUEST = '@@service/GET_SERVICES_REQUEST',

  UPDATE_SERVICE_REQUEST = '@@service/UPDATE_SERVICE_REQUEST',
  UPDATE_SERVICE_SUCCESS = '@@service/UPDATE_SERVICE_SUCCESS',

  DELETE_SERVICE_REQUEST = '@@service/DELETE_SERVICE_REQUEST',
  DELETE_SERVICE_SUCCESS = '@@service/DELETE_SERVICE_SUCCESS',

  SERVICE_REQUEST_FAILURE = '@@service/SERVICE_REQUEST_FAILURE',

  CLEAN_ERRORS = '@@service/CLEAN_ERRORS',
}
