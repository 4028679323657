import RequestService from './request-service';
import { AxiosResponse } from 'axios';
import { IQuery } from '../models/IQuery';

export default class FeedbackService extends RequestService {
  public createFeedback = (
    feedbackInput: { comment: string; rating: string },
    uniqueFeedbackToken: string
  ): Promise<AxiosResponse> =>
    this.instance.post('feedback', feedbackInput, {
      params: { uniqueId: uniqueFeedbackToken },
    });

  public getMasterFeedback = (masterId: string, query: IQuery): Promise<AxiosResponse> =>
    this.instance.get('feedback/master', { params: { masterId, ...query } });

  public deleteFeedback = (feedbackId: string): Promise<AxiosResponse> =>
    this.instance.delete('feedback', { params: { feedbackId } });
}
