import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';
import { composeEnhancers } from './utils';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];
const enhancer = composeEnhancers(applyMiddleware(...middlewares));
const store = createStore(rootReducer(history), {}, enhancer);

sagaMiddleware.run(rootSaga);

export default store;
