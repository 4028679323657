import { DiscountActionTypes } from './types';
import { IDiscount, IDiscountInput, IDiscountUpdateInput } from '../../models/IDiscount';
import { IQuery } from '../../models/IQuery';

type Error = string;

export const getDiscountsRequest = (locationId: string, pagination?: IQuery, filterBy?: any) => ({
  type: DiscountActionTypes.GET_DISCOUNT_REQUEST,
  payload: { locationId, pagination, filterBy },
});
export const getDiscountsSuccess = (discounts: IDiscount[], total: number) => ({
  type: DiscountActionTypes.GET_DISCOUNT_SUCCESS,
  payload: { discounts, total },
});

export const createDiscountRequest = (discount: IDiscountInput, locationId: string) => ({
  type: DiscountActionTypes.CREATE_DISCOUNT_REQUEST,
  payload: { discount, locationId },
});
export const createDiscountSuccess = (discount: IDiscount) => ({
  type: DiscountActionTypes.CREATE_DISCOUNT_SUCCESS,
  payload: discount,
});

export const updateDiscountRequest = (discount: IDiscountUpdateInput, discountId: string) => ({
  type: DiscountActionTypes.UPDATE_DISCOUNT_REQUEST,
  payload: { discount, discountId },
});
export const updateDiscountSuccess = (discount: IDiscount) => ({
  type: DiscountActionTypes.UPDATE_DISCOUNT_SUCCESS,
  payload: discount,
});

export const deleteDiscountRequest = (discountId: string, locationId: string) => ({
  type: DiscountActionTypes.DELETE_DISCOUNT_REQUEST,
  payload: { discountId, locationId },
});
export const deleteDiscountSuccess = (discountId: string) => ({
  type: DiscountActionTypes.DELETE_DISCOUNT_SUCCESS,
  payload: discountId,
});

export const onDiscountRequestFailure = (error: Error) => ({
  type: DiscountActionTypes.CLEAN_ERRORS,
  payload: error,
});
