import RequestService from './request-service';
import { AxiosResponse } from 'axios';
import { ILocationInput, ILocationUpdateInput } from '../models/ILocation';
import { IQuery } from '../models/IQuery';

export default class LocationService extends RequestService {
  public createLocation = (locationInput: ILocationInput): Promise<AxiosResponse> =>
    this.instance.post('location/create-location', locationInput);

  public getLocationInfo = (locationId: string): Promise<AxiosResponse> =>
    this.instance.get('location', { params: { locationId } });

  public getLocationsInfo = (locationIds: string[]): Promise<AxiosResponse> =>
    this.instance.get('location/locations', { params: { locationIds } });

  public getLocationsWidget = (locationId: string): Promise<AxiosResponse> =>
    this.instance.get('location/widget', { params: { locationId } });

  public createLocationsWidget = (widget: any): Promise<AxiosResponse> => this.instance.post('location/widget', widget);

  public updateLocationsWidget = (widget: any): Promise<AxiosResponse> => this.instance.put('location/widget', widget);

  public getLocationStatisticsWorker = (statisticsData: any): Promise<AxiosResponse> =>
    this.instance.get('location/statistics', { params: { ...statisticsData } });

  public deleteLocation = (locationId: string): Promise<AxiosResponse> =>
    this.instance.delete('location', { params: { locationId } });

  public updateLocation = (locationUpdateInput: ILocationUpdateInput, locationId: string): Promise<AxiosResponse> =>
    this.instance.put('location', locationUpdateInput, {
      params: { locationId },
    });

  public addLocationMaster = (body: { master: string }, locationId: string): Promise<AxiosResponse> =>
    this.instance.put('location/add-master', body, { params: { locationId } });

  public removeLocationMaster = (body: { master: string }, locationId: string): Promise<AxiosResponse> =>
    this.instance.put('location/remove-master', body, {
      params: { locationId },
    });

  public getClients = (locationId: string, pagination: IQuery, filterBy: any, queryData: any): Promise<AxiosResponse> =>
    this.instance({
      method: 'get',
      url: 'location/clients',
      params: { locationId, ...pagination, find: filterBy, queryData },
    });
}
