import { all } from 'redux-saga/effects';
import authWatchers from './auth/saga';
import discountWatchers from './discount/saga';
import locationWatchers from './location/saga';
import masterWatchers from './master/saga';
import reservationWatchers from './reservation/saga';
import serviceWatchers from './service/saga';
import subscriptionWatchers from './subscription/saga';
import userWatchers from './user/saga';

export default function* rootSaga() {
  yield all([
    ...authWatchers,
    ...locationWatchers,
    ...masterWatchers,
    ...serviceWatchers,
    ...discountWatchers,
    ...userWatchers,
    ...reservationWatchers,
    ...subscriptionWatchers,
  ]);
}
