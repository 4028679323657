import { Col, Divider, Form, FormItemProps, Input, Row, Select } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_LANG } from '../../const';
import countriesEn from '../../const/countries';
import countriesRu from '../../const/countries.ru';
import countriesUk from '../../const/countries.uk';
import './CountriesFormItem.css';

export const CountriesFormItem: React.FC<CountriesFormItemProps> = ({
  countryCodeName = 'countryCode',
  phoneNumberName = 'phone',
  ...props
}) => {
  const { t } = useTranslation();
  const locale = useMemo(() => localStorage.getItem(APP_LANG), []);
  const countries = useMemo(() => locale === 'ru' ? countriesRu : locale === 'uk' ? countriesUk : countriesEn, [locale]);
  const [filteredCountries, setFilteredCountries] = useState<Country[]>(countries);

  const handleFilterChange = (event: any) => {
    const needle = event.target.value;
    if (!needle) {
      setFilteredCountries(countries);
    } else {
      setFilteredCountries(countries.filter(
        country => `+${country.phone} ${country.name.toLowerCase()}`.includes(needle.toLowerCase())));
    }
  };

  return (
    <Form.Item
      className="countries-form-item"
      name={phoneNumberName}
      label={t('Phone')}
      rules={[
        {
          message: t('Required field'),
          pattern: /^\+?[0-9]{3}-?[0-9]{6,12}$/,
          required: true,
        },
      ]}
      {...props}
    >
      <Input
        type="tel"
        autoComplete="off"
        addonBefore={
          <Form.Item name={countryCodeName} noStyle>
            <Select
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      padding: 8,
                    }}
                  >
                    <Input
                      className={'no-arrows-input'}
                      min={1}
                      placeholder={t('Start entering a code')}
                      style={{ flex: 'auto' }}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              )}
              placeholder={t('Select a country code')}
              style={{ textAlign: 'left', width: 180 }}
            >
              {filteredCountries.map(country => (
                <Select.Option key={country.phone} value={country.phone}>
                  <Row align="middle" justify="space-between" wrap={false}>
                    <Col className="text-wrap country-name">{country.emoji} {country.name}</Col>
                    <Col className="flex-shrink-0">+{country.phone}</Col>
                  </Row>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        placeholder={t('Enter a phone number')}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );
};

type CountriesFormItemProps = FormItemProps & {
  countryCodeName?: string | string[];
  phoneNumberName?: string | string[];
};

type Country = {
  name: string;
  native: string;
  phone: string;
  continent: string;
  capital: string;
  currency: string;
  languages: string[];
  emoji: string;
  emojiU: string;
};
