import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from '../../../../models/ILocation';
import { IService } from '../../../../models/IService';

const MAXIMUM_CHARACTERS = 150;

export const Service = (props: ServiceProps) => {
  const { t } = useTranslation();

  const [fullDescriptionVisible, setFullDescriptionVisible] = useState(false);

  const addEllipsis = (text: string) => text.slice(-3) === '...' ? text :
    ([':', ';', ',', '.', '!', '?'].includes(text.charAt(text.length - 1)) ? text.slice(0, -1) : text) + '...';

  const shortDescription = useMemo(() => props.service.description?.length > MAXIMUM_CHARACTERS
    ? addEllipsis(props.service.description.slice(0, MAXIMUM_CHARACTERS).split(' ').slice(0, -1).join(' '))
    : props.service.description, [props.service.description]);

  const handleClick = (event: any) => {
    if (!props.disabled && !event.target.closest('button')) {
      props.onClick();
    }
  };

  return (
    <li
      className={clsx('list-item', props.disabled && 'list-item--disabled', props.selected && 'list-item--selected')}
      onClick={handleClick}
    >
      <Row align="middle" justify="space-between">
        <Col>
          <b className="title">{props.service.name}</b> <span className="service-duration">{t('{{minutes}} min.', { minutes: props.service.duration })}</span>
          <div>{props.service.maxPerson > 1 ? `(${t('Before')} ${props.service.maxPerson} ${t('People')})` : null}</div>
        </Col>
        <Col className="flex align-items-center">
          <div className="price mr-2">{props.service.price} {props.location.currency}</div>
          {props.selected
            ? <div className="selectivity-icon color-red"><MinusCircleOutlined /></div>
            : <div className="selectivity-icon color-green"><PlusCircleOutlined /></div>}
        </Col>
      </Row>
      <p className="description">{fullDescriptionVisible ? props.service.description : shortDescription}</p>
      {props.service.description?.length > MAXIMUM_CHARACTERS && (
        <div className="flex justify-content-right">
          <Button
            className="more-button"
            size="small"
            type="link"
            onClick={() => setFullDescriptionVisible(!fullDescriptionVisible)}
          >
            {fullDescriptionVisible ? t('Show less') : t('Show more')}
          </Button>
        </div>
      )}
    </li>
  );
};

type ServiceProps = {
  location: ILocation;
  service: IService;
  disabled: boolean;
  selected: boolean;
  onClick: () => any;
};
