import { ISubscription } from '../../models/ISubscription';

export interface SubscriptionState {
  readonly loading: boolean;
  readonly subscriptions: ISubscription[];
}

export enum SubscriptionTypes {
  FETCH_USER_SUBSCRIPTION = '@@subcription/FETCH_USER_SUBSCRIPTIONS',
  FETCH_USER_SUBSCRIPTION_SUCCESS = '@@subcription/FETCH_USER_SUBSCRIPTIONS_SUCCESS',
  FETCH_USER_SUBSCRIPTION_FAIL = '@@subcription/FETCH_USER_SUBSCRIPTIONS_FAIL',

  UPDATE_USER_SUBSCRIPTION = '@@subcription/UPDATE_USER_SUBSCRIPTIONS',
  UPDATE_USER_SUBSCRIPTION_SUCCESS = '@@subcription/UPDATE_USER_SUBSCRIPTIONS_SUCCESS',
  UPDATE_USER_SUBSCRIPTION_FAIL = '@@subcription/FETCH_USER_SUBSCRIPTIONS_FAIL',
}
