import { takeEvery, put, select } from 'redux-saga/effects';
import { UserActionTypes } from './types';
import {
  confirmUserSuccess,
  createUserSuccess,
  deleteUserAccountSuccess,
  loginUserSuccess,
  onClientRequestFailure,
  searchUsersSuccess,
  setCurrentUserSuccess,
  updateUserSuccess,
  userReservationsSuccess,
} from './actions';
import { IUserInput, IUserUpdateInput } from '../../models/IUser';
import UserService from '../../services/user-service';
import { IQuery } from '../../models/IQuery';
import { onLocationRequestFailure } from '../location/actions';
import LocationService from '../../services/location-service';
import { IAuthCredential } from '../../models/IAuthCredential';
import { APP_AUTH_DATA_REFRESH_USER, APP_AUTH_DATA_TOKEN_USER } from '../../const';
import { notification } from 'antd';
import i18n from '../../i18n';
import { IApplicationState } from '../../models/IApplicationState';
import { setWidgetScreen } from '../app/actions';
import { WidgetScreen } from '../app/types';

const userService = new UserService();
const locationService = new LocationService();

// //TODO catch response fail
function* createUserWorker(action: { type: string; payload: { user: IUserInput; locationId: string } }) {
  try {
    const response = yield userService.createUser(action.payload.user, action.payload.locationId);

    if (window.location.pathname !== '/clients') {
      // localStorage.setItem(APP_AUTH_DATA_TOKEN_USER, response.data.token)
      // localStorage.setItem(APP_AUTH_DATA_REFRESH_USER, response.data.refreshToken)
      yield put(loginUserSuccess(response.data.user, true));
    } else {
      yield put(createUserSuccess(response.data.user, true));
    }
  } catch (e) {
    console.log(e);
    yield put(onClientRequestFailure(e.message));
  }
}

function* getLocationUsersWorker(action: {
  type: string;
  payload: {
    locationId: string;
    pagination: IQuery;
    filterBy: any;
    queryData: any;
  };
}) {
  try {
    const response = yield locationService.getClients(
      action.payload.locationId,
      action.payload.pagination,
      action.payload.filterBy,
      action.payload.queryData
    );
    yield put(searchUsersSuccess(response.data.clients, response.data.totalFiltered));
  } catch (e) {
    console.log(e);
    yield put(onLocationRequestFailure(e.message));
  }
}

function* updateUserWorker(action: { type: string; payload: { user: IUserUpdateInput; userId: string } }) {
  try {
    const response = yield userService.updateUser(action.payload.user, action.payload.userId);
    localStorage.setItem(APP_AUTH_DATA_TOKEN_USER, response.data.token);
    localStorage.setItem(APP_AUTH_DATA_REFRESH_USER, response.data.refreshToken);
    yield put(updateUserSuccess(response.data.user));
    notification.success({
      message: i18n.t('Profile has been updated successfully.'),
      duration: 5,
    });
  } catch (e) {
    console.log(e);
    yield put(onClientRequestFailure(e.message));
  }
}

function* loginUserWorker(action: { type: string; payload: IAuthCredential }) {
  try {
    const response = yield userService.login(action.payload);
    localStorage.setItem(APP_AUTH_DATA_TOKEN_USER, response.data.token);
    localStorage.setItem(APP_AUTH_DATA_REFRESH_USER, response.data.refreshToken);

    yield put(
      loginUserSuccess(
        response.data.user,
        response.data.token === undefined && response.data.refreshToken === undefined
      )
    );
    yield put(setWidgetScreen(WidgetScreen.Reservation));
  } catch (e) {
    console.log(e);
    yield put(onClientRequestFailure(e.message));
  }
}

function* onLoadUserWorker(action: { type: string; payload: string }) {
  try {
    const response = yield userService.onLoad(action.payload);
    localStorage.setItem(APP_AUTH_DATA_TOKEN_USER, response.data.token);
    localStorage.setItem(APP_AUTH_DATA_REFRESH_USER, response.data.refreshToken);
    yield put(loginUserSuccess(response.data.user));
  } catch (e) {
    console.log(e);
    yield put(onClientRequestFailure(e.message));
  }
}

function* userReservationWorker(action: { type: string; payload: IQuery }) {
  try {
    const response = yield userService.userReservation(action.payload);

    yield put(userReservationsSuccess(response.data.reservations));
  } catch (e) {
    console.log(e);
    yield put(onClientRequestFailure(e.message));
  }
}

function* newUsersPasswordWorker(action: { type: string; payload: string }) {
  try {
    console.log(action);
    if (!!action.payload) {
      const response = yield userService.requestNewPassword(action.payload);

      if (response.data.sendPassword) {
        notification.success({
          message: `${i18n.t('A new password has been sent.')}`,
          description: i18n.t('Your phone will soon receive a message with a new login password.'),
          duration: 5,
        });
      }
    }
  } catch (e) {
    console.log(e);
    yield put(onClientRequestFailure(e.message));
  }
}

function* deleteUserWorker() {
  try {
    yield userService.deleteUser();
    localStorage.removeItem(APP_AUTH_DATA_TOKEN_USER);
    localStorage.removeItem(APP_AUTH_DATA_REFRESH_USER);

    yield put(deleteUserAccountSuccess());
  } catch (e) {
    console.log(e);
    yield put(onClientRequestFailure(e.message));
  }
}

function* getUserWorker(action: { type: string; payload: string }) {
  try {
    const response = yield userService.getUser(action.payload);

    yield put(setCurrentUserSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(onClientRequestFailure(e.message));
  }
}

function* confirmUserWorker(action: { type: string; payload: string }) {
  try {
    const userId = yield select((state: IApplicationState) => state.user.currentUser?._id);

    const response = yield userService.confirmUser(action.payload, userId);

    localStorage.setItem(APP_AUTH_DATA_TOKEN_USER, response.data.token);
    localStorage.setItem(APP_AUTH_DATA_REFRESH_USER, response.data.refreshToken);

    yield put(confirmUserSuccess());
    yield put(setWidgetScreen(WidgetScreen.Reservation));
    notification.success({
      message: i18n.t('You have been registered successfully.'),
      duration: 5,
    });
  } catch (e) {
    console.log(e);
    yield put(onClientRequestFailure(e.message));
  }
}

export function* watchUser() {
  yield takeEvery(UserActionTypes.LOGIN_USER_REQUEST, loginUserWorker);
  yield takeEvery(UserActionTypes.USER_RESERVATION_REQUEST, userReservationWorker);
  yield takeEvery(UserActionTypes.ON_LOAD_USER_REQUEST, onLoadUserWorker);
  yield takeEvery(UserActionTypes.CREATE_USER_REQUEST, createUserWorker);
  yield takeEvery(UserActionTypes.UPDATE_USER_REQUEST, updateUserWorker);
  yield takeEvery(UserActionTypes.SEARCH_USERS_REQUEST, getLocationUsersWorker);
  yield takeEvery(UserActionTypes.NEW_PASSWORD_REQUEST, newUsersPasswordWorker);
  yield takeEvery(UserActionTypes.DELETE_USER_REQUEST, deleteUserWorker);
  yield takeEvery(UserActionTypes.GET_CURRENT_USER_REQUEST, getUserWorker);
  yield takeEvery(UserActionTypes.CONFIRM_USER_REQUEST, confirmUserWorker);
}

const userWatchers: any = [watchUser()];

export default userWatchers;
