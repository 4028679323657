import { Avatar, Button } from 'antd';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import locationIcon from '../../../assets/icons/reservation/location.svg';
import { ILocation } from '../../../models/ILocation';
import { selectLocations } from '../../../store/location/selectors';
import { Header } from '../Header/Header';
import './Locations.css';

export const Locations = (props: LocationsProps) => {
  const locations = useSelector(selectLocations);

  return (
    <div>
      <Header
        endAdornment={(
          <Button className="icon-button">
            <img src={locationIcon} alt="" />
          </Button>
        )}
        title={<>{t('Choose')} <b>{t('[Slide] location')}</b></>}
        onBack={props.onBack}
      />
      <ul className="skeedee-list reservation-locations">
        {locations.map((location, index) => (
          <li className="list-item" key={index} onClick={() => props.onSelect(location)}>
            <div className="avatar-slot">
              {location.avatarUrl && (
                <Avatar
                  alt={location.name}
                  shape="square"
                  size={60}
                  src={location.avatarUrl}
                />
              )}
            </div>
            <div className="break-word">
              <b className="title">{location.name}</b>
              <div>{location.city} {location.address}</div>
              <div>{location.aboutUs}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

interface LocationsProps {
  onBack: () => void;
  onSelect: (location: ILocation) => void;
}
