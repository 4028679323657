import { Reducer } from 'redux';
import { MasterActionTypes, MasterState } from './types';

export const initialState: MasterState = {
  currentMaster: null,
  error: null,
  feedbacks: [],
  totalFeedback: 0,
  loading: false,
};

const reducer: Reducer<MasterState> = (state = initialState, action) => {
  switch (action.type) {
    case MasterActionTypes.DELETE_FEEDBACK_REQUEST:
    case MasterActionTypes.UPDATE_MASTER_REQUEST:
    case MasterActionTypes.MASTER_INFO_REQUEST: {
      return { ...state, loading: true };
    }
    case MasterActionTypes.DELETE_FEEDBACK_SUCCESS: {
      const feedbacks = state.feedbacks.filter((feedback) => feedback._id !== action.payload);
      return {
        ...state,
        loading: false,
        feedbacks: feedbacks,
        totalFeedback: state.totalFeedback - 1,
      };
    }
    case MasterActionTypes.MASTER_REQUEST_FAILURE: {
      return { ...state, loading: false, error: action.payload };
    }
    case MasterActionTypes.MASTER_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentMaster: action.payload.master,
        feedbacks: action.payload.feedbacks || [],
      };
    }
    case MasterActionTypes.CLEAN_ERRORS: {
      return { ...state, loading: false, error: null };
    }
    default: {
      return state;
    }
  }
};

export { reducer as MasterReducer };
