import React from 'react';
import { useSelector } from 'react-redux';
import { selectWidgetHeader } from '../../../store/app/selectors';
import './Header.css';

export const Header: React.FC = ({ children }) => {
  const header = useSelector(selectWidgetHeader);

  return (
    <div className="widget-header">
      {header ? (
        <>
          <h3>{header.title}</h3>
          {header.subtitle && <div className="subtitle">{header.subtitle}</div>}
        </>
      ) : (
        <h3>{children}</h3>
      )}
    </div>
  );
};
