import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import UserAuth from '../../../components/UserAuth/UserAuth';
import { IApplicationState } from '../../../models/IApplicationState';
import { ILocation } from '../../../models/ILocation';
import { IService } from '../../../models/IService';
import { IMaster } from '../../../models/IUser';
import { Header } from '../Header/Header';
import { ReservationInfo } from '../ReservationInfo/ReservationInfo';

export const DoneScreen = (props: DoneScreenProps) => {
  const { t } = useTranslation();

  const { userId, needConfirm } = useSelector((state: IApplicationState) => ({
    userId: state.user.currentUser?._id,
    needConfirm: state.user.needConfirm,
  }));

  return (
    <div>
      <Header
        title={<>{t('Choose')} <b>{t('[Slide] done')}</b></>}
        onBack={props.onBack}
      />
      {needConfirm || !userId ? (
        <UserAuth />
      ) : props.location && props.master && props.date && props.time && props.services.length && (
        <ReservationInfo
          location={props.location}
          master={props.master}
          date={props.date}
          time={props.time}
          services={props.services}
          onReserved={props.onReserved}
        />
      )}
    </div>
  );
};

interface DoneScreenProps {
  location: ILocation;
  master: IMaster;
  date: string;
  time: string;
  services: IService[];
  onBack: () => void;
  onReserved: () => void;
}
