import RequestService from './request-service';
import { AxiosResponse } from 'axios';
import {
  IGroupReservationInput,
  IGroupReservationUpdateInput,
  IReservationInput,
  IReservationUpdateInput,
} from '../models/IReservation';

export default class ReservationsService extends RequestService {
  public createReservation = (reservationInput: IReservationInput, locationId: string): Promise<AxiosResponse> =>
    this.instance.post('reservation/create-reservation', reservationInput, {
      params: { locationId },
    });

  public getReservation = (reservationId: string): Promise<AxiosResponse> =>
    this.instance.get('reservation', { params: { reservationId } });

  public getGroupReservation = (reservationId: string): Promise<AxiosResponse> =>
    this.instance.get('group-reservation', { params: { reservationId } });

  public getLocationReservations = (locationId: string, date: string): Promise<AxiosResponse> =>
    this.instance({
      method: 'get',
      url: 'reservation/location',
      params: { locationId, date },
    });

  public deleteReservation = (reservationId: string): Promise<AxiosResponse> =>
    this.instance.delete('reservation', { params: { reservationId } });

  public deleteGroupReservation = (reservationId: string): Promise<AxiosResponse> =>
    this.instance.delete('group-reservation', { params: { reservationId } });

  public updateReservation = (
    reservationUpdateInput: IReservationUpdateInput,
    reservationId: string,
    locationId: string,
    updatedBy: string
  ): Promise<AxiosResponse> => {
    return this.instance.put('reservation', reservationUpdateInput, {
      params: { reservationId, locationId, updatedBy },
    });
  };

  public updateGroupReservation = (
    reservationUpdateInput: IGroupReservationUpdateInput,
    reservationId: string,
    locationId: string
  ): Promise<AxiosResponse> =>
    this.instance.put('group-reservation', reservationUpdateInput, {
      params: { reservationId, locationId },
    });

  public createGroupReservation = (
    reservationInput: IGroupReservationInput,
    locationId: string
  ): Promise<AxiosResponse> =>
    this.instance.post('group-reservation/create-group-reservation', reservationInput, { params: { locationId } });
}
