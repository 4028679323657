import RequestService from './request-service';
import { AxiosResponse } from 'axios';
import { IDiscountInput, IDiscountUpdateInput } from '../models/IDiscount';
import { IQuery } from '../models/IQuery';

export default class DiscountService extends RequestService {
  public createDiscount = (discountInput: IDiscountInput, locationId: string): Promise<AxiosResponse> =>
    this.instance.post('discount/create-discount', discountInput, {
      params: { locationId },
    });

  public getDiscounts = (locationId: string, pagination: IQuery, filterBy?: any): Promise<AxiosResponse> =>
    this.instance({
      method: 'get',
      url: '/discount',
      params: { locationId, ...pagination, find: filterBy },
    });

  public updateDiscount = (discountUpdateInput: IDiscountUpdateInput, discountId: string): Promise<AxiosResponse> =>
    this.instance.put('discount', discountUpdateInput, {
      params: { discountId },
    });

  public deleteDiscount = (discountId: string, locationId: string): Promise<AxiosResponse> =>
    this.instance.delete('discount', { params: { discountId, locationId } });
}
