import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { APP_AUTH_DATA_TOKEN_USER } from '../../const';
import { fetchLocationWidget } from '../../services/location.service';
import { setWidgetOptions, unsetWidgetHeader } from '../../store/app/actions';
import { selectWidgetScreen } from '../../store/app/selectors';
import { WidgetScreen } from '../../store/app/types';
import { noToken } from '../../store/auth/actions';
import { clientLocationRequest } from '../../store/location/actions';
import { StoreState } from '../../store/types';
import { onUserLoadRequest } from '../../store/user/actions';
import { ReservationCart } from '../../views/ReservationCart/ReservationCart';
import { AboutUs } from '../AboutUs/AboutUs';
import { Bookings } from '../Bookings/Bookings';
import { Loader } from '../Loader/Loader';
import { Profile } from '../Profile/Profile';
import { Screen } from './Screen/Screen';
import { Toolbar } from './Toolbar/Toolbar';
import './Widget.css';

export const Widget: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, screen } = useSelector((state: StoreState) => ({
    loading: state.user.loading && state.location.loading,
    screen: selectWidgetScreen(state),
  }));

  useEffect(() => {
    const token = localStorage.getItem(APP_AUTH_DATA_TOKEN_USER);
    if (token) {
      dispatch(onUserLoadRequest(token));
    } else {
      dispatch(noToken());
    }
  }, [dispatch]);

  useEffect(() => {
    let [, query]: any[] = location.search.split('?');
    if (query) {
      query = query.split('&');
      const widgetId = query[0].replace('widgetId=', '');
      fetchLocationWidget(widgetId)
        .then(({ data: widget }) => {
          dispatch(setWidgetOptions(widget.locations, widget.about));
          dispatch(clientLocationRequest(widget.locations));
          document.documentElement.classList.add(`skeedee-${widget.style}`);
        })
        .catch(error => console.error(error));
    }
  }, [location.search, dispatch]);

  useEffect(() => {
    if (screen !== WidgetScreen.Reservation) {
      dispatch(unsetWidgetHeader());
    }
  }, [screen, dispatch]);

  const currentScreenElement = useMemo(() => {
    if (loading) {
      return <Loader />;
    }

    switch (screen) {
      case WidgetScreen.MyBookings:
        return (
          <Screen title={t('My bookings')}>
            <Bookings />
          </Screen>
        );
      case WidgetScreen.Profile:
        return (
          <Screen title={t('Profile')}>
            <Profile />
          </Screen>
        );
      case WidgetScreen.AboutUs:
        return (
          <Screen title={t('About us')}>
            <AboutUs />
          </Screen>
        );
      default:
        return (
          <Screen title={t('Make a reservation')}>
            <ReservationCart />
          </Screen>
        );
    }
  }, [loading, screen, t]);

  return (
    <div className="widget-viewport">
      {currentScreenElement}
      <Toolbar />
    </div>
  );
};
