import { ReservationActionTypes } from './types';
import { IMaster } from '../../models/IUser';
import {
  IGroupReservation,
  IGroupReservationInput,
  IGroupReservationUpdateInput,
  IReservation,
  IReservationInput,
  IReservationUpdateInput,
} from '../../models/IReservation';

type Error = string;

export const setUpDate = (date: string) => ({
  type: ReservationActionTypes.SET_DATE,
  payload: date,
});

export const reservationRequest = (date: string, locationId: string) => ({
  type: ReservationActionTypes.RESERVATION_REQUEST,
  payload: { date, locationId },
});
export const reservationSuccess = (masterData: IMaster[]) => ({
  type: ReservationActionTypes.RESERVATION_SUCCESS,
  payload: masterData,
});

export const createReservationRequest = (locationId: string, reservationInput: IReservationInput) => ({
  type: ReservationActionTypes.CREATE_RESERVATION_REQUEST,
  payload: { locationId, reservation: reservationInput },
});

export const createReservationSuccess = (reservation: IReservation) => ({
  type: ReservationActionTypes.CREATE_RESERVATION_SUCCESS,
  payload: reservation,
});

export const updateReservationRequest = (
  reservationInput: IReservationUpdateInput,
  reservationId: string,
  masterId: string,
  locationId?: string
) => ({
  type: ReservationActionTypes.UPDATE_RESERVATION_REQUEST,
  payload: {
    reservationId,
    reservation: reservationInput,
    masterId,
    locationId,
  },
});

export const updateGroupReservationRequest = (
  reservationInput: IGroupReservationUpdateInput,
  reservationId: string,
  masterId: string
) => ({
  type: ReservationActionTypes.UPDATE_GROUP_RESERVATION_REQUEST,
  payload: { reservationId, reservation: reservationInput, masterId },
});

export const updateGroupReservationSuccess = (reservation: IGroupReservation) => ({
  type: ReservationActionTypes.UPDATE_GROUP_RESERVATION_SUCCESS,
  payload: reservation,
});

export const createGroupReservationRequest = (locationId: string, groupReservationInput: IGroupReservationInput) => ({
  type: ReservationActionTypes.CREATE_GROUP_RESERVATION_REQUEST,
  payload: { locationId, reservation: groupReservationInput },
});

export const createGroupReservationSuccess = (reservation: IReservation) => ({
  type: ReservationActionTypes.CREATE_GROUP_RESERVATION_SUCCESS,
  payload: reservation,
});

export const updateReservationSuccess = (reservation: IReservation) => ({
  type: ReservationActionTypes.UPDATE_RESERVATION_SUCCESS,
  payload: reservation,
});

export const getReservationRequest = (reservationId: string) => ({
  type: ReservationActionTypes.GET_RESERVATION_REQUEST,
  payload: reservationId,
});

export const getReservationSuccess = (reservation: IReservation) => ({
  type: ReservationActionTypes.GET_RESERVATION_SUCCESS,
  payload: reservation,
});

export const getGroupReservationRequest = (reservationId: string) => ({
  type: ReservationActionTypes.GET_GROUP_RESERVATION_REQUEST,
  payload: reservationId,
});

export const getGroupReservationSuccess = (reservation: IGroupReservation) => ({
  type: ReservationActionTypes.GET_GROUP_RESERVATION_SUCCESS,
  payload: reservation,
});

export const deleteReservationRequest = (reservationId: string) => ({
  type: ReservationActionTypes.DELETE_RESERVATION_REQUEST,
  payload: reservationId,
});

export const deleteReservationSuccess = (reservationId: string) => ({
  type: ReservationActionTypes.DELETE_RESERVATION_SUCCESS,
  payload: reservationId,
});

export const deleteGroupReservationRequest = (reservationId: string) => ({
  type: ReservationActionTypes.DELETE_GROUP_RESERVATION_REQUEST,
  payload: reservationId,
});

export const deleteGroupReservationSuccess = (reservationId: string) => ({
  type: ReservationActionTypes.DELETE_GROUP_RESERVATION_SUCCESS,
  payload: reservationId,
});

export const wssReservationPush = (reservation: IReservation | IGroupReservation) => ({
  type: ReservationActionTypes.WSS_PUSH_RESERVATION,
  payload: reservation,
});

export const wssReservationUpdate = (
  reservation: IReservation,
  masterData: { prev: string; next: string },
  userId: string
) => ({
  type: ReservationActionTypes.WSS_UPDATE_RESERVATION,
  payload: { reservation, masterData, userId },
});

export const wssReservationRemove = (reservationId: string, masterId: string) => ({
  type: ReservationActionTypes.WSS_REMOVE_RESERVATION,
  payload: { reservationId, masterId },
});

export const clearSelectedReservation = () => ({
  type: ReservationActionTypes.CLEAR_SELECTED_RESERVATION,
});

export const onReservationRequestFailure = (data: Error) => ({
  type: ReservationActionTypes.RESERVATION_REQUEST_FAILURE,
  payload: data,
});

export const cleanErrors = () => ({
  type: ReservationActionTypes.CLEAN_ERRORS,
});
