import { LoadingOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Loader.css';

export const Loader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="skeedee-loader">
      <LoadingOutlined />
      <Typography.Title level={2}>{t('Loading')}...</Typography.Title>
    </div>
  );
};
