import { LocationActionTypes } from './types';
import { ILocation, ILocationInput, ILocationUpdateInput, IWidget } from '../../models/ILocation';
import { IMasterInput } from '../../models/IUser';

type Error = string;

export const locationRequest = (locationId: string) => ({
  type: LocationActionTypes.LOCATION_REQUEST,
  payload: locationId,
});
export const locationSuccess = (data: ILocation) => ({
  type: LocationActionTypes.LOCATION_SUCCESS,
  payload: data,
});

export const setUpLocation = (data: { locations: ILocation[]; currentLocation: ILocation }) => ({
  type: LocationActionTypes.SET_UP_LOCATION,
  payload: data,
});

export const createLocationRequest = (locationInput: ILocationInput) => ({
  type: LocationActionTypes.CREATE_LOCATION_REQUEST,
  payload: locationInput,
});
export const createLocationSuccess = (location: ILocation) => ({
  type: LocationActionTypes.CREATE_LOCATION_SUCCESS,
  payload: location,
});

export const addMasterRequest = (masterInput: IMasterInput) => ({
  type: LocationActionTypes.ADD_MASTER_REQUEST,
  payload: masterInput,
});
export const addMasterSuccess = (data: {}) => ({
  type: LocationActionTypes.ADD_MASTER_SUCCESS,
  payload: data,
});

export const removeMasterSuccess = (masterId: string) => ({
  type: LocationActionTypes.REMOVE_MASTER,
  payload: masterId,
});

export const selectLocationRequest = (locationId: string) => ({
  type: LocationActionTypes.SELECT_LOCATION_REQUEST,
  payload: locationId,
});
export const selectLocationSuccess = (location: ILocation) => ({
  type: LocationActionTypes.SELECT_LOCATION_SUCCESS,
  payload: location,
});

export const clientLocationRequest = (locationIds: string[]) => ({
  type: LocationActionTypes.CLIENT_LOCATION_REQUEST,
  payload: locationIds,
});
export const clientLocationSuccess = (locations: ILocation[]) => ({
  type: LocationActionTypes.CLIENT_LOCATION_SUCCESS,
  payload: locations,
});

export const updateLocationRequest = (locationUpdateInput: ILocationUpdateInput, locationId: string) => ({
  type: LocationActionTypes.UPDATE_LOCATION_REQUEST,
  payload: { locationUpdateInput, locationId },
});

export const getLocationWidgetSuccess = (widget: IWidget) => ({
  type: LocationActionTypes.GET_LOCATION_WIDGET_SUCCESS,
  payload: widget,
});

export const getLocationWidgetRequest = (locationId: string) => ({
  type: LocationActionTypes.GET_LOCATION_WIDGET_REQUEST,
  payload: locationId,
});

export const createLocationWidgetRequest = (widget: any) => ({
  type: LocationActionTypes.CREATE_LOCATION_WIDGET_REQUEST,
  payload: widget,
});

export const updateLocationWidgetRequest = (widget: any) => ({
  type: LocationActionTypes.UPDATE_LOCATION_WIDGET_REQUEST,
  payload: widget,
});

export const getLocationStatisticsRequest = (statisticsData: any) => ({
  type: LocationActionTypes.GET_LOCATION_STATISTICS_REQUEST,
  payload: statisticsData,
});
export const getLocationStatisticsSuccess = (baseStatistics: any) => ({
  type: LocationActionTypes.GET_LOCATION_STATISTICS_SUCCESS,
  payload: baseStatistics,
});

export const clearStatistics = () => ({
  type: LocationActionTypes.CLEAR_STATISTICS,
});

export const onLocationRequestFailure = (data: Error) => ({
  type: LocationActionTypes.LOCATION_REQUEST_FAILURE,
  payload: data,
});
