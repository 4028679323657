import { Button, Col, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { changeUserPassword } from '../../../services/user.service';

export const PasswordChange = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    changeUserPassword(values.currentPassword, values.newPassword)
      .then(() => {
        message.success('Password has been changed successfully.');
        form.resetFields();
      })
      .catch(console.error);
  };

  const validateNewPassword = async (rule: any, value: string) => {
    if (value && value === form.getFieldsValue().currentPassword) {
      return Promise.reject(new Error());
    }
  };

  const validateRepeatedPassword = async (rule: any, value: string) => {
    if (value && value !== form.getFieldsValue().newPassword) {
      return Promise.reject(new Error());
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Col span={24}>
        <Form.Item name="currentPassword" label={t('Current password')} rules={[{ required: true, message: t('Required field') }]}>
          <Input placeholder={t('Enter current password')} type="password" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="newPassword"
          label={t('New password')}
          rules={[
            { required: true, message: t('Required field') },
            { validator: validateNewPassword, message: t('New password must be different from the current password') }
          ]}
        >
          <Input placeholder={t('Enter new password')} type="password" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="repeatedPassword"
          label={t('Repeat password')}
          rules={[
            { required: true, message: t('Required field') },
            { validator: validateRepeatedPassword, message: t('The password must be the same as the new password') }
          ]}
        >
          <Input placeholder={t('Enter new password again')} type="password" />
        </Form.Item>
      </Col>
      <div className="text-right">
        <Button type="primary" htmlType="submit">{t('Change')}</Button>
      </div>
    </Form>
  );
};
