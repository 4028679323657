import React from 'react';

export const MenuItem = ({ Icon, ...props }: MenuItemProps) => (
  <li className={props.active ? 'active' : ''} onClick={props.onClick}>
    <Icon />
    <span>{props.title}</span>
  </li>
);

type MenuItemProps = {
  active: boolean;
  Icon: React.FC;
  title: string;
  onClick: () => unknown;
};
