import { IMaster } from '../../models/IUser';

export interface AuthState {
  readonly loading: boolean;
  readonly user: IMaster | null;
  readonly error: string | null;
}

export enum AuthActionTypes {
  LOGIN_REQUEST = '@@auth/LOGIN_REQUEST',
  LOGIN_FAILURE = '@@auth/AUTH_REQUEST_FAILURE',
  LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS',

  ON_LOAD_REQUEST = '@@auth/ON_LOAD_REQUEST',

  NO_TOKEN = '@@auth/NO_TOKEN',

  SIGN_UP_REQUEST = '@@auth/SIGN_UP_REQUEST',
  SIGN_UP_FAILURE = '@@auth/SIGN_UP_FAILURE',
  SIGN_UP_SUCCESS = '@@auth/SIGN_UP_SUCCESS',

  UPDATE_TOKEN = '@@auth/UPDATE_TOKEN',

  REFRESH_TOKEN_REQUEST = '@@auth/REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS = '@@auth/REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE = '@@auth/AUTH_REQUEST_FAILURE',

  LOGOUT_SUCCESS = '@@auth/LOGOUT_SUCCESS',
  LOGOUT_FAILURE = '@@auth/LOGOUT_FAILURE',
  LOGOUT_REQUEST = '@@auth/LOGOUT_REQUEST',

  CLEAN_ERRORS = '@@auth/CLEAN_ERRORS',
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
}
