import React from 'react';

export const InfoIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.8995 4.10049C21.2553 1.45627 17.7395 0 14 0C11.0509 0 8.22961 0.90683 5.84109 2.62265C5.59586 2.79889 5.53967 3.14069 5.71591 3.38593C5.89215 3.63116 6.23395 3.68735 6.47919 3.51111C8.68057 1.92966 11.2812 1.09375 14 1.09375C21.1164 1.09375 26.9062 6.88358 26.9062 14C26.9062 21.1164 21.1164 26.9062 14 26.9062C6.88358 26.9062 1.09375 21.1164 1.09375 14C1.09375 11.281 1.92944 8.68057 3.51068 6.47961C3.68692 6.23416 3.63095 5.89258 3.38571 5.71634C3.14026 5.5401 2.79868 5.59607 2.62244 5.84131C0.90683 8.22961 0 11.0507 0 14C0 17.7395 1.45627 21.2553 4.10049 23.8995C6.74472 26.5437 10.2605 28 14 28C17.7395 28 21.2553 26.5437 23.8995 23.8995C26.5437 21.2553 28 17.7395 28 14C28 10.2605 26.5437 6.74472 23.8995 4.10049Z" fill="black"/>
    <path d="M23.0572 20.7221C23.308 20.8904 23.6479 20.8235 23.816 20.5725C25.1221 18.6256 25.8125 16.3528 25.8125 14C25.8125 10.8448 24.5837 7.87842 22.3527 5.64713C20.1216 3.41626 17.1552 2.1875 14 2.1875C10.8448 2.1875 7.87842 3.41626 5.64734 5.64734C3.41626 7.87842 2.1875 10.8448 2.1875 14C2.1875 17.1552 3.41626 20.1216 5.64734 22.3527C7.87842 24.5837 10.8448 25.8125 14 25.8125C16.3533 25.8125 18.6262 25.1221 20.5732 23.8158C20.824 23.6474 20.8908 23.3078 20.7225 23.057C20.5542 22.8062 20.2143 22.7393 19.9637 22.9077C18.1977 24.0924 16.1356 24.7188 14 24.7188C8.08969 24.7188 3.28125 19.9103 3.28125 14C3.28125 8.08969 8.08969 3.28125 14 3.28125C19.9103 3.28125 24.7188 8.08969 24.7188 14C24.7188 16.1352 24.0926 18.1973 22.9077 19.9633C22.7395 20.2141 22.8064 20.554 23.0572 20.7221Z" fill="black"/>
    <path d="M4.48698 5.03403C4.62712 5.03403 4.76704 4.98062 4.87385 4.87381C5.08747 4.6604 5.08747 4.31412 4.87385 4.10049C4.66023 3.88687 4.31416 3.88687 4.10054 4.10049L4.10032 4.10071C3.8867 4.31433 3.88691 4.6604 4.10032 4.87402C4.20713 4.98083 4.34706 5.03403 4.48698 5.03403Z" fill="black"/>
    <path d="M21.5616 21.5979L21.5614 21.5981C21.3582 21.8216 21.3747 22.1672 21.5983 22.3704C21.703 22.4657 21.8346 22.5127 21.966 22.5127C22.1147 22.5127 22.2629 22.4522 22.371 22.3336C22.5742 22.1102 22.5575 21.7641 22.3341 21.561C22.1106 21.3578 21.7648 21.3743 21.5616 21.5979Z" fill="black"/>
    <path d="M10.9976 20.0156C10.9976 20.9203 11.7335 21.6562 12.6382 21.6562H15.3619C16.2666 21.6562 17.0025 20.9203 17.0025 20.0156C17.0025 19.206 16.4131 18.5314 15.6407 18.3987V12.3594C15.6407 11.4549 14.9047 10.7188 14 10.7188H12.6382C11.7335 10.7188 10.9976 11.4549 10.9976 12.3594C10.9976 13.1692 11.5872 13.8436 12.3594 13.9763V18.3987C11.5869 18.5314 10.9976 19.206 10.9976 20.0156ZM12.9063 12.9062H12.6382C12.3365 12.9062 12.0913 12.661 12.0913 12.3594C12.0913 12.058 12.3365 11.8125 12.6382 11.8125H14C14.3017 11.8125 14.5469 12.058 14.5469 12.3594V18.9219C14.5469 19.2239 14.7917 19.4688 15.0938 19.4688H15.3619C15.6635 19.4688 15.9088 19.7142 15.9088 20.0156C15.9088 20.3173 15.6635 20.5625 15.3619 20.5625H12.6382C12.3365 20.5625 12.0911 20.3173 12.0911 20.0156C12.0911 19.7142 12.3365 19.4688 12.6382 19.4688H12.9063C13.2081 19.4688 13.4532 19.2239 13.4532 18.9219V13.4531C13.4532 13.1511 13.2083 12.9062 12.9063 12.9062Z" fill="black"/>
    <path d="M15.3125 7.98438C15.3125 7.07968 14.5766 6.34375 13.6719 6.34375C12.7672 6.34375 12.0312 7.07968 12.0312 7.98438C12.0312 8.88907 12.7672 9.625 13.6719 9.625C14.5766 9.625 15.3125 8.88907 15.3125 7.98438ZM13.125 7.98438C13.125 7.68274 13.3702 7.4375 13.6719 7.4375C13.9735 7.4375 14.2188 7.68274 14.2188 7.98438C14.2188 8.2858 13.9735 8.53125 13.6719 8.53125C13.3702 8.53125 13.125 8.28601 13.125 7.98438Z" fill="black"/>
  </svg>
);
