import { Avatar, Button, Rate } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ILocation } from '../../models/ILocation';
import { IService } from '../../models/IService';
import { IMaster } from '../../models/IUser';
import { convertTimeTo12H } from '../../services/utils';
import { selectLocations } from '../../store/location/selectors';
import { CartItem } from './CartItem/CartItem';
import { DoneScreen } from './DoneScreen/DoneScreen';
import { Locations } from './Locations/Locations';
import { Masters } from './Masters/Masters';
import './ReservationCart.css';
import { Services } from './Services/Services';
import { ServicesCartItem } from './ServicesCartItem/ServicesCartItem';
import { Timesheet } from './Timesheet/Timesheet';

export const ReservationCart = () => {
  const { i18n, t } = useTranslation();

  const [done, setDone] = useState<boolean>(false);
  const [location, setLocation] = useState<ILocation | null>(null);
  const [master, setMaster] = useState<IMaster | null>(null);
  const [date, setDate] = useState<string | null>(null);
  const [time, setTime] = useState<string | null>(null);
  const [availableMinutes, setAvailableMinutes] = useState<number | null>(null);
  const [services, setServices] = useState<IService[]>([]);

  const [locationsVisible, setLocationsVisible] = useState<boolean>(false);
  const [_mastersVisible, setMastersVisible] = useState<boolean>(false);
  const [_timesheetVisible, setTimesheetVisible] = useState<boolean>(false);
  const [_servicesVisible, setServicesVisible] = useState<boolean>(false);

  const locations = useSelector(selectLocations);

  const mastersVisible = useMemo(() => _mastersVisible && location, [_mastersVisible, location]);
  const timesheetVisible = useMemo(() => _timesheetVisible && location, [_timesheetVisible, location]);
  const servicesVisible = useMemo(() => _servicesVisible && location, [_servicesVisible, location]);

  const dateLabel = useMemo(() => capitalize(
    moment(date).toDate().toLocaleDateString(moment.locale(), { month: 'long', day: 'numeric' })), [date]);
  const totalPrice = useMemo(() => services.reduce((price, service) => price + parseFloat(service.price), 0), [services]);

  useEffect(() => () => void moment.updateLocale(i18n.language, { week: { dow: 0 }}), [i18n.language]);

  const handleLocationSelect = (_location: ILocation) => {
    handleLocationsClose();
    if (!location || _location._id !== location._id) {
      setAvailableMinutes(null);
      setServices([]);
      setDate(null);
      setTime(null);
      setMaster(null);
    }
    setLocation(_location);
  };
  const handleMasterSelect = (master: IMaster, date?: string, time?: string, availableMinutes?: number) => {
    handleMastersClose();
    setMaster(master);
    if (date) {
      setDate(date);
    }
    if (time) {
      setTime(time);
    }
    if (availableMinutes) {
      setAvailableMinutes(availableMinutes);
    }
  };
  const handleTimeSelect = (date: string, time: string, availableMinutes: number) => {
    handleTimesheetClose();
    setDate(date);
    setTime(time);
    setAvailableMinutes(availableMinutes);
  };
  const handleServicesChange = (services: IService[]) => setServices(services);

  useEffect(() => {
    if (locations.length === 1) {
      handleLocationSelect(locations[0]);
    }
  }, [locations.length]);

  const handleLoationClear = () => {
    setServices([]);
    setAvailableMinutes(null);
    setDate(null);
    setTime(null);
    setMaster(null);
    setLocation(null);
  };
  const handleMasterClear = () => {
    setMaster(null);
    setAvailableMinutes(null);
  }
  const handleTimeClear = () => {
    setDate(null);
    setTime(null);
    setAvailableMinutes(null);
  };

  const handleLocationsOpen = () => locations.length > 1 && setLocationsVisible(true);
  const handleMastersOpen = () => setMastersVisible(true);
  const handleTimesheetOpen = () => setTimesheetVisible(true);
  const handleServicesOpen = () => setServicesVisible(true);

  const handleLocationsClose = () => setLocationsVisible(false);
  const handleMastersClose = () => setMastersVisible(false);
  const handleTimesheetClose = () => setTimesheetVisible(false);
  const handleServicesClose = () => setServicesVisible(false);

  const handleContinue = () => setDone(true);
  const handleDoneScreenClose = () => setDone(false);

  const handleReserved = () => {
    setDone(false);
    setServices([]);
    setAvailableMinutes(null);
    setDate(null);
    setTime(null);
    setMaster(null);
    setLocation(locations.length > 1 ? null : locations[0]);
  };

  return (
    <>
      {locationsVisible && (
        <Locations
          onBack={handleLocationsClose}
          onSelect={handleLocationSelect}
        />
      )}
      {mastersVisible && (
        <Masters
          location={location!}
          date={date}
          time={time}
          services={services}
          onBack={handleMastersClose}
          onSelect={handleMasterSelect}
          onReviewsClick={() => {}}
          onTimeSelect={handleMasterSelect}
        />
      )}
      {timesheetVisible && (
        <Timesheet
          date={date}
          location={location!}
          master={master}
          services={services}
          onBack={handleTimesheetClose}
          onSelect={handleTimeSelect}
        />
      )}
      {servicesVisible && (
        <Services
          availableMinutes={availableMinutes}
          location={location!}
          master={master}
          services={services}
          totalPrice={totalPrice}
          onBack={handleServicesClose}
          onChange={handleServicesChange}
        />
      )}

      {!locationsVisible && !mastersVisible && !timesheetVisible && !servicesVisible && !done && (
        <>
          <ul className="reservation-cart skeedee-list">
            <CartItem
              avatar={location?.avatarUrl && (
                <Avatar
                  alt={location.name}
                  shape="circle"
                  size={48}
                  src={location.avatarUrl}
                />
              )}
              clearable={!!location && locations.length > 1}
              clickable={locations.length > 1}
              onClear={handleLoationClear}
              onClick={handleLocationsOpen}
            >
              {location ? (
                <div className="flex flex-column flex-grow-1">
                  <div className="text-16 text-bold">{location.name}</div>
                  <div className="text-12">{location.address}</div>
                </div>
              ) : t('Choose a location')}
            </CartItem>
            <CartItem
              avatar={master?.avatarUrl && (
                <Avatar
                  alt={master.name}
                  shape="square"
                  size={60}
                  src={master.avatarUrl}
                />
              )}
              clearable={!!master}
              disabled={!location}
              onClear={handleMasterClear}
              onClick={handleMastersOpen}
            >
              {master ? (
                <div className="flex flex-column flex-grow-1">
                  <div className="text-16 text-bold">{master.name}</div>
                  <div className="text-12">{master.specialization}</div>
                  <div>
                    <Rate disabled defaultValue={master.avgRating} />
                  </div>
                </div>
              ) : t('Choose a master')}
            </CartItem>
            <CartItem
              clearable={!!date && !!time}
              disabled={!location}
              onClear={handleTimeClear}
              onClick={handleTimesheetOpen}
            >
              {date && time ? (
                <div className="flex flex-column flex-grow-1">
                  <div className="text-12 color-gray">{dateLabel}</div>
                  <div className="text-16">{location!.is12H ? convertTimeTo12H(time) : time}</div>
                </div>
              ) : t('Choose a date and time')}
            </CartItem>
            <ServicesCartItem
              disabled={!location}
              location={location!}
              services={services}
              totalPrice={totalPrice}
              onChange={handleServicesChange}
              onClick={handleServicesOpen}
            />
          </ul>
          {location && master && date && time && !!services.length && (
            <Button
              className="reservation-cart--continue-button flex"
              onClick={handleContinue}
            >
              <span className="flex-grow-1 text-center">{t('Continue')}</span>
            </Button>
          )}
        </>
      )}

      {done && (
        <DoneScreen
          location={location!}
          master={master!}
          date={date!}
          time={time!}
          services={services}
          onBack={handleDoneScreenClose}
          onReserved={handleReserved}
        />
      )}
    </>
  );
};
