import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import { Button, Checkbox, Col, Form, Input, Popconfirm, Row, Select } from 'antd';
import { APP_AUTH_DATA_REFRESH_USER, APP_AUTH_DATA_TOKEN_USER, APP_LANG, USER_ID, languages } from '../../../const';
import { IApplicationState } from '../../../models/IApplicationState';
import UserAuth from '../../UserAuth/UserAuth';
import { deleteUserAccountRequest, updateUserRequest, userLogout } from '../../../store/user/actions';
import { IUserUpdateInput } from '../../../models/IUser';
import { momentLangSetup } from '../../../i18n';
import { CountriesFormItem } from '../../CountriesFormItem/CountriesFormItem';

const Wrapper = styled.div``;

type Props = {};

export const PersonalData: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [userForm] = Form.useForm();

  const { authenticated, user, needConfirm } = useSelector((state: IApplicationState) => ({
    authenticated: !!state.user.currentUser,
    user: state.user.currentUser,
    needConfirm: state.user.needConfirm,
  }));

  useEffect(() => {
    const language = localStorage.getItem(APP_LANG) || 'en';
    form.setFieldsValue({ language });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    userForm.setFieldsValue({
      name: user?.name,
      email: user?.email,
      countryCode: user?.countryCode,
      phone: user?.phone,
      subscription: user?.subscription,
    });
    // eslint-disable-next-line
  }, [user]);

  const logoutHandler = () => {
    localStorage.removeItem(APP_AUTH_DATA_TOKEN_USER);
    localStorage.removeItem(APP_AUTH_DATA_REFRESH_USER);
    localStorage.removeItem(USER_ID);
    dispatch(userLogout());
  };

  const onFinish = (values: any) => {
    dispatch(updateUserRequest(values as IUserUpdateInput, user?._id as string));
  };

  const deleteAccountHandler = () => {
    dispatch(deleteUserAccountRequest());
  };

  return (
    <Wrapper>
      <Form form={form} layout="vertical">
        <Form.Item name="language" label={t('Language')}>
          <Select
            style={{ alignItems: 'center' }}
            onChange={(lang) => {
              localStorage.setItem(APP_LANG, lang as string);
              //@ts-ignore
              moment.locale(lang, momentLangSetup[lang]);
              i18n.changeLanguage(lang as string);
            }}
          >
            {languages.map((language) => (
              <Select.Option key={language} value={language} label={t(`[Language] ${language}`)}>
                {t(`[Language] ${language}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      {authenticated && !needConfirm ? (
        <Form form={userForm} layout="vertical" onFinish={onFinish}>
          <Form.Item name="name" label={t('Name')} rules={[{ required: true, message: t('Required field') }]}>
            <Input />
          </Form.Item>

          <CountriesFormItem />

          <Form.Item name="email" label={t('E-mail')} rules={[{ type: 'email', message: t('Empty field or wrong data') }]}>
            <Input placeholder={t('E-mail')} />
          </Form.Item>

          <Form.Item name="subscription" valuePropName="checked">
            <Checkbox>{t('Subscription')}</Checkbox>
          </Form.Item>

          <Form.Item>
            <Row gutter={8}>
              <Col>
                <Button type="primary" htmlType="submit" style={{ borderRadius: 10, marginBottom: 8 }}>
                  {t('Update')}
                </Button>
              </Col>
              <Col>
                <Button onClick={logoutHandler} type="dashed" style={{ borderRadius: 10 }}>
                  {t('Logout')}
                </Button>
              </Col>
              <Col>
                <Popconfirm
                  onConfirm={deleteAccountHandler}
                  title={t('Are you sure?')}
                  okText={t('Yes')}
                  cancelText={t('No')}
                >
                  <Button danger type="dashed" style={{ borderRadius: 10 }}>
                    {t('Delete Account')}
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        <UserAuth />
      )}
    </Wrapper>
  );
};
