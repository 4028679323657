import { PhoneOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import { Button, Popconfirm, Space } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IService } from '../../../../models/IService';
import { IMaster } from '../../../../models/IUser';
import { CreateCalendarEventButtons } from '../../../CreateCalendarEventButtons/CreateCalendarEventButtons';
import { IReservation } from '../../../../models/IReservation';
import { ILocation } from '../../../../models/ILocation';
import { getTimeFormat } from '../../../../services/utils';
import './Reservation.css';

export const Reservation = (props: ReservationProps) => {
  const { t } = useTranslation();

  const [createCalendarEventButtonsVisible, setCreateCalendarEventButtonsVisible] = useState(false);

  const master = useMemo<IMaster | null>(() => props.reservation.master as IMaster, [props.reservation]);
  const service = useMemo<IService>(() => props.reservation.service as IService, [props.reservation]);

  const reservedAt = useMemo(() =>
    moment.utc(props.reservation.reservedAt).add(props.location.utcOffset || 0, 'minutes'),
    [props.reservation, props.location]);

  const timeFormat = useMemo(() => getTimeFormat(props.location.is12H), [props.location.is12H]);

  return (
    <li className="list-item">
      <span className={`status status--${props.reservation.status}`} />
      <b className="title">{service.name}</b>
      <ul className="reservation-info">
        {master && <li>{master?.name}</li>}
        <li>{reservedAt.format(`LL ${timeFormat}`)}</li>
        <li>{service.price} {props.location.currency}</li>
        <li className="location-info">
          {props.location.avatarUrl && <img alt={props.location.name} src={props.location.avatarUrl} height="40" />}
          <ul>
            <li>{props.location.name}</li>
            <li>{props.location.address}</li>
            {!!props.location.phones?.length && (
              <li><PhoneOutlined /> {props.location.phones.map((phone, index) => (
                <React.Fragment key={index}>
                  <a href={`tel:+${phone.countryCode + phone.phone}`}>+{phone.countryCode + phone.phone}</a>
                  {index < props.location.phones.length - 1 ? ', ' : ''}
                </React.Fragment>
              ))}</li>
            )}
          </ul>
        </li>
      </ul>
      <Space className="actions" size="small" wrap>
        {props.reservation.feedbackUrl && props.reservation.status !== 'inProgress' && props.reservation.status !== 'canceled' && (
          <a href={props.reservation.feedbackUrl} target="_blank" rel="noopener noreferrer">
            {t('Leave a comment')}
          </a>
        )}
        {(props.reservation.status === 'created' || props.reservation.status === 'inProgress') && (
          <Popconfirm
            cancelText={t('No')}
            okText={t('Yes')}
            title={t('Are you sure?')}
            onConfirm={() => props.onReservationCancel(props.reservation)}
          >
            <Button danger size="small" type="dashed">
              {t('Cancel the reservation')}
            </Button>
          </Popconfirm>
        )}
        {props.reservation.status === 'created' && (
          createCalendarEventButtonsVisible ? (
            <CreateCalendarEventButtons
              calendarOptions={{
                title: service.name,
                location: props.location.address,
                start: reservedAt.toDate(),
                end: reservedAt.add(props.reservation.duration, 'minutes').toDate(),
              }}
            />
          ) : (
            <Button
              size="small"
              type="dashed"
              onClick={() => setCreateCalendarEventButtonsVisible(true)}
            >
              {t('Add event to calendar')}
            </Button>
          )
        )}
        {props.reservation.status === 'done' && (
          <Button
            size="small"
            type="dashed"
            onClick={() => props.onReservationRepeat(props.reservation)}
          >
            {t('Repeat')}
          </Button>
        )}
      </Space>
    </li>
  );
};

type ReservationProps = {
  location: ILocation;
  reservation: IReservation;
  onReservationCancel: (reservation: IReservation) => void;
  onReservationRepeat: (reservation: IReservation) => void;
};
