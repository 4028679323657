import { takeEvery, put } from 'redux-saga/effects';
import { SubscriptionTypes } from './types';
import {
  getSubscriptionRequestSuccess,
  updateUserSubscriptionRequestFail,
  updateUserSubscriptionRequestSuccess,
} from './actions';
import SubscriptionService from '../../services/subscription.service';

import { AxiosResponse } from 'axios';
import { onServiceRequestFailure } from '../service/actions';
import { push } from 'react-router-redux';
import { notification } from 'antd';
import i18n from '../../i18n';

const subscriptionService = new SubscriptionService();

function* getSubscriptionWorker(action: {
  type: string;
  payload: {
    locationId?: string;
  };
}) {
  try {
    const response: AxiosResponse = yield subscriptionService.getUserSubscriptions(action.payload.locationId);
    yield put(getSubscriptionRequestSuccess(response.data.userSubscriptions));
  } catch (e) {
    console.log(e);
    yield put(onServiceRequestFailure(e.message));
  }
}

function* updateUserSubscriptionWorker(action: {
  type: string;
  payload: {
    plan_id: string;
    subscription_id: string;
  };
}) {
  try {
    const response: AxiosResponse = yield subscriptionService.updateUserSubscriptions(action.payload);
    yield put(push(`/billing`));
    notification.success({
      message: i18n.t('Subscription has updated successfully.'),
    });
    yield put(updateUserSubscriptionRequestSuccess(response));
  } catch (e) {
    yield put(updateUserSubscriptionRequestFail());
    console.log(e);
    yield put(onServiceRequestFailure(e.message));
  }
}

export function* watchSubcription() {
  yield takeEvery(SubscriptionTypes.FETCH_USER_SUBSCRIPTION, getSubscriptionWorker);
  yield takeEvery(SubscriptionTypes.UPDATE_USER_SUBSCRIPTION, updateUserSubscriptionWorker);
}

const subscriptionWatchers: any = [watchSubcription()];

export default subscriptionWatchers;
