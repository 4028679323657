import RequestService from './request-service';
import { AxiosResponse } from 'axios';
import { IServiceInput, IServiceUpdateInput } from '../models/IService';
import { IQuery } from '../models/IQuery';

export default class ServiceService extends RequestService {
  public createService = (
    serviceInput: IServiceInput,
    locationId: string,
    assignOptions: { masters?: string[]; assignToAll?: boolean }
  ): Promise<AxiosResponse> =>
    this.instance.post('service/create-service', serviceInput, {
      params: { locationId, ...assignOptions },
    });

  public getServices = (locationId: string, pagination: IQuery, findBy?: any): Promise<AxiosResponse> =>
    this.instance({
      method: 'get',
      url: 'service',
      params: { locationId, ...pagination, find: findBy },
    });

  public updateService = (serviceUpdateInput: IServiceUpdateInput, serviceId: string): Promise<AxiosResponse> =>
    this.instance.put('service', serviceUpdateInput, {
      data: serviceUpdateInput,
      params: { serviceId },
    });

  public deleteService = (serviceId: string, locationId: string): Promise<AxiosResponse> =>
    this.instance.delete('service', { params: { serviceId, locationId } });
}
