import { Alert, Badge, Button, Empty, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from '../../../models/ILocation';
import { IService } from '../../../models/IService';
import { IMaster } from '../../../models/IUser';
import { Header } from '../Header/Header';
import { Service } from './Service/Service';
import './Services.css';

export const Services = (props: ServicesProps) => {
  const { t } = useTranslation();

  const isSubscriptionActive = useMemo(() => props.location?.subscriptions?.filter(subscription =>
    subscription?.status === 'trialing' || subscription?.status === 'active')?.length, [props.location]);
  const servicesSelected = useMemo<Record<string, boolean>>(() => props.services.reduce((selected, service) =>
    Object.assign(selected, { [service._id]: true }), {}), [props.services]);
  const services = useMemo(() => (props.location?.services as IService[])
    ?.filter(service => service.maxPerson <= 1), [props.location]);

  const availableMinutes = useMemo(() => {
    if (props.availableMinutes === null) {
      return null;
    }
    const servicesDurationInMinutes = props.services.reduce((minutes, service) => minutes + service.duration + service.pauseAfter, 0);
    return props.availableMinutes - servicesDurationInMinutes;
  }, [props.availableMinutes, props.services]);

  const handleServiceToggle = (service: IService) => {
    const index = props.services.findIndex(({ _id }) => _id === service._id);
    if (index >= 0) {
      const services = [...props.services];
      services.splice(index, 1);
      props.onChange(services);
    } else {
      props.onChange([...props.services, service]);
    }
  };

  return (
    <div>
      <Header
        title={<>{t('Choose')} <b>{t('[Slide] services')}</b></>}
        onBack={props.onBack}
      />
      {isSubscriptionActive ? services.length ? (
        <ul className="skeedee-list reservation-services">
          {services.map((service, index) => (
            <Service
              key={index}
              location={props.location}
              service={service}
              disabled={!servicesSelected[service._id] && availableMinutes !== null && service.duration + service.pauseAfter > availableMinutes}
              selected={servicesSelected[service._id]}
              onClick={() => handleServiceToggle(service)}
            />
          ))}
        </ul>
      ) : <Empty /> : (
        <Alert
          description={<Row justify="space-between">{t('This branch is blocked because you have no active subscription plan. If this is your website, please, go to skeedee.com and activate a subscription plan.')}</Row>}
          message={t('This branch is blocked')}
          showIcon
          style={{ marginBottom: '24px' }}
          type="warning"
        />
      )}
      {!!props.services.length && (
        <Button
          className="reservation-cart--continue-button flex"
          onClick={() => props.onBack()}
        >
          <span className="flex-grow-1">{t('Continue')}</span>
          <Badge className="flex-shrink-0" count={`${props.totalPrice} ${props.location.currency}`} />
        </Button>
      )}
    </div>
  );
};

interface ServicesProps {
  availableMinutes: number | null;
  location: ILocation;
  master: IMaster | null;
  services: IService[];
  totalPrice: number;
  onBack: () => void;
  onChange: (services: IService[]) => void;
}
