import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordChange } from './PasswordChange/PasswordChange';
import { PersonalData } from './PersonalData/PersonalData';

export const Profile = () => {
  const { t } = useTranslation();

  return (
    <Tabs defaultActiveKey="personal-data">
      <Tabs.TabPane tab={t('Personal data')} key="personal-data">
        <PersonalData />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('Change password')} key="password-change">
        <PasswordChange />
      </Tabs.TabPane>
    </Tabs>
  );
};
