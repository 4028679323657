import { Reducer } from 'redux';
import { AuthActionTypes, AuthState } from './types';

export const initialState: AuthState = {
  user: null,
  error: null,
  loading: true,
};

const reducer: Reducer<AuthState> = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.NO_TOKEN: {
      return { ...state, loading: false };
    }
    case AuthActionTypes.REFRESH_TOKEN_REQUEST:
    case AuthActionTypes.ON_LOAD_REQUEST:
    case AuthActionTypes.LOGOUT_REQUEST:
    case AuthActionTypes.SIGN_UP_REQUEST:
    case AuthActionTypes.LOGIN_REQUEST: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.REFRESH_TOKEN_FAILURE:
    case AuthActionTypes.LOGOUT_FAILURE:
    case AuthActionTypes.SIGN_UP_FAILURE:
    case AuthActionTypes.LOGIN_FAILURE: {
      return { ...state, loading: false, error: action.payload };
    }
    case AuthActionTypes.LOGOUT_SUCCESS:
    case AuthActionTypes.SIGN_UP_SUCCESS:
    case AuthActionTypes.LOGIN_SUCCESS: {
      return { ...state, loading: false, user: action.payload };
    }
    case AuthActionTypes.CLEAN_ERRORS: {
      return { ...state, loading: false, error: null };
    }
    default: {
      return state;
    }
  }
};

export { reducer as AuthReducer };
