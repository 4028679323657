export interface IEnv {
  wssUrl: string;
  apiUrl: string;
  uploadUrl: string;
  captchaKey: string;
  googleMapKey: string;
  widgetUrl: string;
}

const development: IEnv = {
  wssUrl: `ws://${window.location.hostname}:5001`,
  apiUrl: `http://${window.location.hostname}:5000/api/v1/`,
  uploadUrl: `http://localhost:5000`,
  widgetUrl: `http://localhost:5003`,
  captchaKey: '6LeW-7QZAAAAADgOuSb5FAZrH0EztZJdh6S4lU0n',
  googleMapKey: 'AIzaSyAbaMh2ORbTc6wpxPySejyiYXMKOXKTueE',
};

const production: IEnv = {
  widgetUrl: `https://widget.skeedee.com`,
  uploadUrl: `https://api.skeedee.com`,
  wssUrl: `wss://api.skeedee.com:444`,
  apiUrl: `https://api.skeedee.com/api/v1/`,
  captchaKey: '6Ldd3tYZAAAAACIeeu2JsEAwyONniQjmgq__B4ud',
  googleMapKey: 'AIzaSyAbaMh2ORbTc6wpxPySejyiYXMKOXKTueE',
};

const environments: { [key: string]: IEnv } = {
  development,
  production,
};

export const env: IEnv = environments[process.env.NODE_ENV] || production;
