import { Reducer } from 'redux';
import { ReservationActionTypes, ReservationState } from './types';
import moment from 'moment';
import { IMaster } from '../../models/IUser';
import { replaceInArray } from '../../services/utils';
import { IReservation } from '../../models/IReservation';

export const initialState: ReservationState = {
  masterData: [],
  total: 0,
  date: new Date().toISOString(),
  error: null,
  selectedReservation: null,
  masterToUpdate: null,
  loading: false,
};

const reducer: Reducer<ReservationState> = (state = initialState, action) => {
  switch (action.type) {
    case ReservationActionTypes.SET_DATE: {
      return { ...state, date: action.payload };
    }
    case ReservationActionTypes.CREATE_RESERVATION_SUCCESS: {
      // if (moment(state.date).isSame(action.payload.reservedAt, 'day')) {
      //
      //     const masters = state.masterData.map((master: IMaster) => {
      //         if (master._id === action.payload.master._id) {
      //             action.payload.master = action.payload.master._id
      //             master.reservations.push(action.payload)
      //         }
      //         return master
      //     })
      //     return {...state, masterData: masters, loading: false};
      // }
      return { ...state, loading: false };
    }
    case ReservationActionTypes.UPDATE_GROUP_RESERVATION_SUCCESS:
    case ReservationActionTypes.UPDATE_RESERVATION_SUCCESS: {
      if (moment(state.date).isSame(action.payload.reservedAt, 'day')) {
        let masters: any = [];

        action.payload.master = action.payload.master._id ? action.payload.master._id : action.payload.master;

        if (action.payload.master === state.masterToUpdate) {
          masters = state.masterData.map((master: IMaster) => {
            if (master._id === action.payload.master) {
              master.reservations = [...replaceInArray(master.reservations, action.payload._id, action.payload)];
            }
            return master;
          });
        } else {
          masters = state.masterData.map((master: IMaster) => {
            if (master._id === action.payload.master) {
              // @ts-ignore
              const isPresent: any[] = master.reservations.filter(
                (reservation: IReservation) => reservation._id === action.payload._id
              );
              if (isPresent.length === 0) {
                master.reservations.push(action.payload);
              }
            }
            if (master._id === state.masterToUpdate) {
              master.reservations = (master.reservations as IReservation[]).filter(
                (reservation: IReservation) => reservation._id !== action.payload._id
              );
            }
            return master;
          });
        }

        return {
          ...state,
          masterData: masters,
          loading: false,
          masterToUpdate: null,
          selectedReservation:
            action.type === ReservationActionTypes.UPDATE_GROUP_RESERVATION_SUCCESS ? action.payload : null,
        };
      }
      return {
        ...state,
        loading: false,
        masterToUpdate: null,
        selectedReservation:
          action.type === ReservationActionTypes.UPDATE_GROUP_RESERVATION_SUCCESS ? action.payload : null,
      };
    }
    case ReservationActionTypes.DELETE_GROUP_RESERVATION_SUCCESS:
    case ReservationActionTypes.DELETE_RESERVATION_SUCCESS: {
      const masters = state.masterData.map((master: IMaster) => {
        master.reservations = (master.reservations as IReservation[]).filter(
          (reservation: IReservation) => reservation._id !== action.payload
        );
        return master;
      });
      return {
        ...state,
        loading: false,
        masterData: masters,
        selectedReservation: null,
      };
    }

    case ReservationActionTypes.WSS_PUSH_RESERVATION: {
      if (moment(state.date).isSame(action.payload.reservedAt, 'day')) {
        const masters = state.masterData.map((master: IMaster) => {
          if (
            master._id ===
            (typeof action.payload.master._id === 'string' ? action.payload.master._id : action.payload.master)
          ) {
            master.reservations.push(action.payload);
          }
          return master;
        });
        return { ...state, masterData: masters };
      }
      return state;
    }

    case ReservationActionTypes.WSS_REMOVE_RESERVATION: {
      const masters = state.masterData.map((master: IMaster) => {
        if (master._id === action.payload.masterId) {
          master.reservations = (master.reservations as IReservation[]).filter(
            (reservation: IReservation) => reservation._id !== action.payload.reservationId
          );
        }
        return master;
      });
      return { ...state, masterData: masters };
    }

    case ReservationActionTypes.WSS_UPDATE_RESERVATION: {
      let masters: any;

      masters = state.masterData.map((master: IMaster) => {
        //@ts-ignore
        master.reservations.map((reservation: IReservation) => {
          if (reservation._id === action.payload.reservation._id) {
            Object.assign(reservation, action.payload.reservation);
          }
          return reservation;
        });
        return master;
      });
      return { ...state, masterData: masters };
    }

    case ReservationActionTypes.UPDATE_GROUP_RESERVATION_REQUEST:
    case ReservationActionTypes.UPDATE_RESERVATION_REQUEST: {
      return {
        ...state,
        loading: true,
        masterToUpdate: action.payload.masterId,
      };
    }
    case ReservationActionTypes.GET_GROUP_RESERVATION_SUCCESS:
    case ReservationActionTypes.GET_RESERVATION_SUCCESS: {
      return { ...state, loading: false, selectedReservation: action.payload };
    }
    case ReservationActionTypes.CLEAR_SELECTED_RESERVATION: {
      return { ...state, selectedReservation: null };
    }
    case ReservationActionTypes.GET_RESERVATION_REQUEST: {
      return { ...state, loading: true, selectedReservation: null };
    }

    case ReservationActionTypes.CREATE_RESERVATION_REQUEST:
    case ReservationActionTypes.DELETE_RESERVATION_REQUEST:
    case ReservationActionTypes.DELETE_GROUP_RESERVATION_REQUEST:
    case ReservationActionTypes.CREATE_GROUP_RESERVATION_REQUEST:
    case ReservationActionTypes.GET_GROUP_RESERVATION_REQUEST:
    case ReservationActionTypes.RESERVATION_REQUEST: {
      return { ...state, loading: true };
    }
    case ReservationActionTypes.RESERVATION_SUCCESS: {
      return { ...state, loading: false, masterData: action.payload };
    }
    case ReservationActionTypes.CREATE_GROUP_RESERVATION_SUCCESS: {
      return { ...state, loading: false };
    }
    case ReservationActionTypes.RESERVATION_REQUEST_FAILURE: {
      return { ...state, loading: false, error: action.payload };
    }
    case ReservationActionTypes.CLEAN_ERRORS: {
      return { ...state, loading: false, error: null };
    }
    default: {
      return state;
    }
  }
};

export { reducer as ReservationReducer };
