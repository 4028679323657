import { Button, Form, Input, notification, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../models/IApplicationState';
import { ILocation } from '../../../models/ILocation';
import { IService } from '../../../models/IService';
import { IMaster } from '../../../models/IUser';
import { createReservation } from '../../../services/reservation.service';
import { getRussianRemainer } from '../../../services/utils';

const REMINDER_HOURS = [1, 2, 3, 4, 5, 6, 9, 12, 15, 18, 21, 24, 48, 72, 120, 168];

export const ReservationInfo = (props: ReservationInfoProps) => {
  const { t, i18n: { language }} = useTranslation();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { userId } = useSelector((state: IApplicationState) => ({
    userId: state.user.currentUser?._id,
  }));

  useEffect(() => {
    form.setFieldsValue({ reminder: 2 });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReserve = async (values: any) => {
    setLoading(true);
    try {
      let additionalMinutes = 0;
      for (const service of props.services) {
        const reservedAt = moment.utc(props.date)
          .subtract(props.location!.utcOffset || 0, 'minutes')
          .utcOffset(props.location!.utcOffset || 0);
        reservedAt
          .set({
            hours: Number(props.time!.split(':')[0]),
            minutes: Number(props.time!.split(':')[1]),
            seconds: 0,
            milliseconds: 0,
          })
          .add(additionalMinutes, 'minutes');
        additionalMinutes += service.duration + service.pauseAfter;
        await createReservation(props.location!._id, {
          master: props.master!._id,
          locationAdmin: props.location!.owner,
          service: service._id,
          reservedAt: reservedAt.toDate(),
          discounts: [],
          client: userId,
          duration: service.duration,
          pauseAfter: service.pauseAfter,
          comment: values.comment,
          reminderHours: values.reminder,
        });
      }
      notification.success({ message: t('Reservation created') });
      props.onReserved();
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
    form.resetFields();
  };

  return (
    <div>
      <div className="flex flex-column">
        <div className="flex text-bold">
          <div className="flex-grow-1">{t('Service')}</div>
          <div className="flex-shrink-0">{t('Price')}</div>
        </div>
        {props.services.map((service, index) => (
          <div key={index} className="flex text-bold">
            <div className="flex-grow-1">{service.name}</div>
            <div className="flex-shrink-0">{service.price} {props.location.currency}</div>
          </div>
        ))}
      </div>
      <div className="h4">
        <b>{t('Master')}:</b>{' '}
        {props.master.name}
      </div>
      <div className="h4">
        <b>{t('Reservation time')}:</b>{' '}
        {`${props.time}, ${moment(props.date).format('DD MMMM, dddd')}`}
      </div>
      <Form className="mt-3" layout="vertical" form={form} onFinish={handleReserve}>
        <Form.Item name="reminder" label={t('Reminder')}>
          <Select>
            <Select.Option value={0} label={t('Do not send')}>{t('Do not send')}</Select.Option>
            {REMINDER_HOURS.map(hours => {
              const label = hours > 24
                ? language === 'ru' || language === 'uk'
                  ? t('remindDaysInAdvance_interval', {
                      postProcess: 'interval',
                      count: getRussianRemainer(hours / 24 % 100),
                      days: hours / 24,
                    })
                  : t('remindDaysInAdvance', { count: hours / 24 })
                : language === 'ru' || language === 'uk'
                  ? t('remindHoursInAdvance_interval', {
                      postProcess: 'interval',
                      count: getRussianRemainer(hours % 100),
                      hours,
                    })
                  : t('remindHoursInAdvance', { count: hours });
              return <Select.Option key={hours} value={hours} label={label}>{label}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item name="comment" label={t('Comment or additional information')}>
          <Input.TextArea rows={2} />
        </Form.Item>

        <div>
          <Button loading={loading} htmlType="submit" type="primary">
            {t('Create')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

interface ReservationInfoProps {
  location: ILocation;
  master: IMaster;
  date: string;
  time: string;
  services: IService[];
  onReserved: () => any;
};
