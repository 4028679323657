import { Button } from 'antd';
import React, { ReactElement } from 'react';
import leftArrowIcon from '../../../assets/icons/reservation/left-arrow.svg';
import './Header.css';

export const Header = (props: HeaderProps) => (
  <div className="reservation-header">
    <div className="flex-shrink-0">
      <Button className="icon-button" onClick={props.onBack}>
        <img src={leftArrowIcon} alt="" />
      </Button>
    </div>
    <div className="label-slot">
      <div>{props.title}</div>
    </div>
    {props.endAdornment && <div className="flex-shrink-0">{props.endAdornment}</div>}
  </div>
);

type HeaderProps = {
  endAdornment?: ReactElement;
  title: ReactElement;
  onBack: () => void;
};
