import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../Header/Header';
import './Screen.css';

export const Screen = ({ children, title }: ScreenProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Header>{title}</Header>
      <div className="flex-grow-1">{children}</div>
      <div className="provider-link flex-shrink-0">
        {t('Booking service by')} <a href="https://skeedee.com" target="_blank" rel="noopener noreferrer">skeedee.com</a>
      </div>
    </>
  );
};

type ScreenProps = {
  children: JSX.Element;
  title: string;
};
