import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { appReducer } from './app/reducer';
import { AuthReducer } from './auth/reducer';
import { DiscountReducer } from './discount/reducer';
import { LocationReducer } from './location/reducer';
import { MasterReducer } from './master/reducer';
import { ReservationReducer } from './reservation/reducer';
import { ServiceReducer } from './service/reducer';
import { SubscriptionReducer } from './subscription/reducer';
import { UserReducer } from './user/reducer';

const rootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  app: appReducer,
  auth: AuthReducer,
  location: LocationReducer,
  master: MasterReducer,
  service: ServiceReducer,
  discount: DiscountReducer,
  user: UserReducer,
  reservation: ReservationReducer,
  subscriptions: SubscriptionReducer,
});

export default rootReducer;
