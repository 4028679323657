import { IReservation } from '../../models/IReservation';

export interface ReservationState {
  readonly loading: boolean;
  readonly masterData: any;
  readonly date: string;
  readonly total: number;
  readonly selectedReservation: IReservation | null;
  readonly masterToUpdate: string | null;
  readonly error: string | null;
}

export enum ReservationActionTypes {
  SET_DATE = '@@reservation/SET_DATE',
  CLEAR_SELECTED_RESERVATION = '@@reservation/CLEAR_SELECTED_RESERVATION',

  RESERVATION_REQUEST = '@@reservation/RESERVATION_REQUEST',
  RESERVATION_SUCCESS = '@@reservation/RESERVATION_SUCCESS',

  CREATE_RESERVATION_REQUEST = '@@reservation/CREATE_RESERVATION_REQUEST',
  CREATE_RESERVATION_SUCCESS = '@@reservation/CREATE_RESERVATION_SUCCESS',

  UPDATE_RESERVATION_REQUEST = '@@reservation/UPDATE_RESERVATION_REQUEST',
  UPDATE_RESERVATION_SUCCESS = '@@reservation/UPDATE_RESERVATION_SUCCESS',

  UPDATE_GROUP_RESERVATION_REQUEST = '@@reservation/UPDATE_GROUP_RESERVATION_REQUEST',
  UPDATE_GROUP_RESERVATION_SUCCESS = '@@reservation/UPDATE_GROUP_RESERVATION_SUCCESS',

  CREATE_GROUP_RESERVATION_REQUEST = '@@reservation/CREATE_GROUP_RESERVATION_REQUEST',
  CREATE_GROUP_RESERVATION_SUCCESS = '@@reservation/CREATE_GROUP_RESERVATION_SUCCESS',

  GET_RESERVATION_REQUEST = '@@reservation/GET_RESERVATION_REQUEST',
  GET_RESERVATION_SUCCESS = '@@reservation/GET_RESERVATION_SUCCESS',

  GET_GROUP_RESERVATION_REQUEST = '@@reservation/GET_GROUP_RESERVATION_REQUEST',
  GET_GROUP_RESERVATION_SUCCESS = '@@reservation/GET_GROUP_RESERVATION_SUCCESS',

  DELETE_RESERVATION_REQUEST = '@@reservation/DELETE_RESERVATION_REQUEST',
  DELETE_RESERVATION_SUCCESS = '@@reservation/DELETE_RESERVATION_SUCCESS',

  DELETE_GROUP_RESERVATION_REQUEST = '@@reservation/DELETE_GROUP_RESERVATION_REQUEST',
  DELETE_GROUP_RESERVATION_SUCCESS = '@@reservation/DELETE_GROUP_RESERVATION_SUCCESS',

  WSS_PUSH_RESERVATION = '@@reservation/WSS_PUSH_RESERVATION',

  WSS_REMOVE_RESERVATION = '@@reservation/WSS_REMOVE_RESERVATION',

  WSS_UPDATE_RESERVATION = '@@reservation/WSS_UPDATE_RESERVATION',

  RESERVATION_REQUEST_FAILURE = '@@reservation/RESERVATION_REQUEST_FAILURE',

  CLEAN_ERRORS = '@@reservation/CLEAN_ERRORS',
}
