import { DeleteOutlined } from '@ant-design/icons';
import React, { PropsWithChildren, ReactElement } from 'react';

export const CartItem = (props: PropsWithChildren<CartItemProps>) => {
  const handleClick = (event: any) => {
    if (!props.disabled && !event.target.closest('.icon-button')) {
      props.onClick();
    }
  };

  return (
    <li
      className={'list-item flex align-items-center' + (props.disabled ? ' list-item--disabled' : '') +
        (props.clickable || props.clickable === undefined ? ' list-item--clickable' : '')}
      onClick={handleClick}
    >
      <div className="icon-slot flex align-items-center justify-content-center flex-shrink-0">{props.avatar || ''}</div>
      {props.children}
      {props.clearable && (
        <div
          className="icon-button flex align-items-center justify-content-center flex-shrink-0"
          onClick={() => props.onClear()}
        >
          <DeleteOutlined />
        </div>
      )}
    </li>
  );
};

interface CartItemProps {
  avatar?: ReactElement | null | '';
  clearable?: boolean;
  clickable?: boolean;
  disabled?: boolean;
  onClear: () => void;
  onClick: () => void;
}
