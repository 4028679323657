import { ILocation, ILocationBaseStatistics, IWidget } from '../../models/ILocation';

export interface LocationState {
  readonly loading: boolean;
  readonly currentLocation: ILocation | null;
  readonly widget: IWidget | null;
  readonly locations: ILocation[];
  readonly error: string | null;
  readonly baseStatistics: null | ILocationBaseStatistics;
}

export enum LocationActionTypes {
  LOCATION_REQUEST = '@@location/LOCATION_REQUEST',
  LOCATION_SUCCESS = '@@location/LOCATION_SUCCESS',

  GET_LOCATION_STATISTICS_REQUEST = '@@location/GET_LOCATION_STATISTICS_REQUEST',
  GET_LOCATION_STATISTICS_SUCCESS = '@@location/GET_LOCATION_STATISTICS_SUCCESS',

  SELECT_LOCATION_REQUEST = '@@location/SELECT_LOCATION_REQUEST',
  SELECT_LOCATION_SUCCESS = '@@location/SELECT_LOCATION_SUCCESS',

  CLIENT_LOCATION_REQUEST = '@@location/CLIENT_LOCATION_REQUEST',
  CLIENT_LOCATION_SUCCESS = '@@location/CLIENT_LOCATION_SUCCESS',

  CREATE_LOCATION_REQUEST = '@@location/CREATE_LOCATION_REQUEST',
  CREATE_LOCATION_SUCCESS = '@@location/CREATE_LOCATION_SUCCESS',

  SET_UP_LOCATION = '@@location/SET_UP_LOCATION',

  REMOVE_MASTER = '@@location/REMOVE_MASTER',

  CLEAR_STATISTICS = '@@location/CLEAR_STATISTICS',

  ADD_MASTER_REQUEST = '@@location/ADD_MASTER_REQUEST',
  ADD_MASTER_SUCCESS = '@@location/ADD_MASTER_SUCCESS',

  UPDATE_LOCATION_REQUEST = '@@location/UPDATE_LOCATION_REQUEST',

  SEARCH_CLIENTS_REQUEST = '@@location/SEARCH_CLIENTS_REQUEST',
  SEARCH_CLIENTS_SUCCESS = '@@location/SEARCH_CLIENTS_SUCCESS',

  UPDATE_LOCATION_WIDGET_REQUEST = '@@location/UPDATE_LOCATION_WIDGET_REQUEST',
  UPDATE_LOCATION_WIDGET_SUCCESS = '@@location/UPDATE_LOCATION_WIDGET_SUCCESS',

  CREATE_LOCATION_WIDGET_REQUEST = '@@location/CREATE_LOCATION_WIDGET_REQUEST',
  CREATE_LOCATION_WIDGET_SUCCESS = '@@location/CREATE_LOCATION_WIDGET_SUCCESS',

  GET_LOCATION_WIDGET_SUCCESS = '@@location/GET_LOCATION_WIDGET_SUCCESS',
  GET_LOCATION_WIDGET_REQUEST = '@@location/GET_LOCATION_WIDGET_REQUEST',

  LOCATION_REQUEST_FAILURE = '@@location/LOCATION_REQUEST_FAILURE',

  CLEAN_ERRORS = '@@auth/CLEAN_ERRORS',
}
