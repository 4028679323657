import { Reducer } from 'redux';
import { ServiceActionTypes, ServiceState } from './types';
import { IService } from '../../models/IService';
import { replaceInArray } from '../../services/utils';

export const initialState: ServiceState = {
  services: [] as IService[],
  error: null,
  total: 0,
  loading: false,
};

const reducer: Reducer<ServiceState> = (state = initialState, action) => {
  switch (action.type) {
    case ServiceActionTypes.GET_SERVICES_REQUEST:
    case ServiceActionTypes.DELETE_SERVICE_REQUEST:
    case ServiceActionTypes.UPDATE_SERVICE_REQUEST:
    case ServiceActionTypes.ADD_SERVICE_REQUEST: {
      return { ...state, loading: true };
    }
    case ServiceActionTypes.GET_SERVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        services: action.payload.services,
        total: action.payload.total,
      };
    }
    case ServiceActionTypes.DELETE_SERVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        services: [...state.services.filter((service: IService) => service._id !== action.payload)],
        total: state.total - 1,
      };
    }
    case ServiceActionTypes.UPDATE_SERVICE_SUCCESS: {
      const services = replaceInArray(state.services, action.payload._id, action.payload);
      return { ...state, loading: false, services: [...services] };
    }
    case ServiceActionTypes.ADD_SERVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        services: [action.payload, ...state.services],
        total: state.total + 1,
      };
    }
    case ServiceActionTypes.SERVICE_REQUEST_FAILURE: {
      return { ...state, loading: false, error: action.payload };
    }
    case ServiceActionTypes.CLEAN_ERRORS: {
      return { ...state, loading: false, error: null };
    }
    default: {
      return state;
    }
  }
};

export { reducer as ServiceReducer };
