import { SubscriptionTypes } from './types';
import { ISubscription } from '../../models/ISubscription';

type Error = string;

export const getSubscriptionRequest = (locationId?: string) => {
  return {
    type: SubscriptionTypes.FETCH_USER_SUBSCRIPTION,
    payload: {
      locationId,
    },
  };
};

export const getSubscriptionRequestSuccess = (subscriptions: ISubscription[]) => ({
  type: SubscriptionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
  payload: { subscriptions },
});

export const getSubscriptionRequestFail = () => ({
  type: SubscriptionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
  payload: { subscriptions: [], error: 'payload.error' },
});

export const updateUserSubscriptionRequest = ({
  plan_id,
  subscription_id,
}: {
  plan_id: string;
  subscription_id: string;
}) => {
  return {
    type: SubscriptionTypes.UPDATE_USER_SUBSCRIPTION,
    payload: {
      plan_id,
      subscription_id,
    },
  };
};

export const updateUserSubscriptionRequestSuccess = (response: any) => ({
  type: SubscriptionTypes.UPDATE_USER_SUBSCRIPTION_SUCCESS,
  payload: { asd: 'Asd' },
});

export const updateUserSubscriptionRequestFail = () => ({
  type: SubscriptionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
  payload: { subscriptions: [], error: 'payload.error' },
});
