import { ServiceActionTypes } from './types';
import { IService, IServiceInput, IServiceUpdateInput } from '../../models/IService';
import { IQuery } from '../../models/IQuery';

type Error = string;

export const getServicesRequest = (locationId: string, pagination?: IQuery, filterBy?: any) => ({
  type: ServiceActionTypes.GET_SERVICES_REQUEST,
  payload: { locationId, pagination, filterBy },
});
export const getServicesSuccess = (services: IService[], total: number) => ({
  type: ServiceActionTypes.GET_SERVICES_SUCCESS,
  payload: { services, total },
});

export const addServiceRequest = (
  serviceInput: IServiceInput,
  locationId: string,
  assignOptions: { masters?: string[]; assignToAll?: boolean }
) => ({
  type: ServiceActionTypes.ADD_SERVICE_REQUEST,
  payload: { serviceInput, locationId, assignOptions },
});
export const addServiceSuccess = (service: IService) => ({
  type: ServiceActionTypes.ADD_SERVICE_SUCCESS,
  payload: service,
});

export const updateServiceRequest = (service: IServiceUpdateInput, serviceId: string) => ({
  type: ServiceActionTypes.UPDATE_SERVICE_REQUEST,
  payload: { service, serviceId },
});
export const updateServiceSuccess = (service: IService) => ({
  type: ServiceActionTypes.UPDATE_SERVICE_SUCCESS,
  payload: service,
});

export const deleteServiceRequest = (serviceId: string, locationId: string) => ({
  type: ServiceActionTypes.DELETE_SERVICE_REQUEST,
  payload: { serviceId, locationId },
});
export const deleteServiceSuccess = (serviceId: string) => ({
  type: ServiceActionTypes.DELETE_SERVICE_SUCCESS,
  payload: serviceId,
});

export const onServiceRequestFailure = (data: Error) => ({
  type: ServiceActionTypes.SERVICE_REQUEST_FAILURE,
  payload: data,
});
