import { Button } from 'antd';
import { CalendarOptions, GoogleCalendar, ICalendar, OutlookCalendar, YahooCalendar } from 'datebook';
import React, { useMemo } from 'react';

export const CreateCalendarEventButtons = (props: CreateCalendarEventButtonsProps) => {
  const calendarEventLinks: Record<string, string> | null = useMemo(() => props.calendarOptions && {
    google: new GoogleCalendar(props.calendarOptions!).render(),
    outlook: new OutlookCalendar(props.calendarOptions!).render(),
    yahoo: new YahooCalendar(props.calendarOptions!).render(),
  }, [props.calendarOptions]);

  const handleIosCalendarEventCreate = () => new ICalendar(props.calendarOptions!).download();

  return (
    <div>
      <Button type="link" target="_blank" href={calendarEventLinks?.google}>Google</Button>
      <Button type="link" target="_blank" href={calendarEventLinks?.outlook}>Outlook</Button>
      <Button type="link" target="_blank" href={calendarEventLinks?.yahoo}>Yahoo!</Button>
      <Button type="link" onClick={handleIosCalendarEventCreate}>iOS</Button>
    </div>
  );
};

interface CreateCalendarEventButtonsProps {
  calendarOptions: CalendarOptions | null;
}
