import { Reducer } from 'redux';
import { DiscountActionTypes, DiscountState } from './types';
import { replaceInArray } from '../../services/utils';
import { IDiscount } from '../../models/IDiscount';

export const initialState: DiscountState = {
  discounts: [],
  error: null,
  total: 0,
  loading: false,
};

const reducer: Reducer<DiscountState> = (state = initialState, action) => {
  switch (action.type) {
    case DiscountActionTypes.DELETE_DISCOUNT_REQUEST:
    case DiscountActionTypes.UPDATE_DISCOUNT_REQUEST:
    case DiscountActionTypes.GET_DISCOUNT_REQUEST:
    case DiscountActionTypes.CREATE_DISCOUNT_REQUEST: {
      return { ...state, loading: true };
    }
    case DiscountActionTypes.CREATE_DISCOUNT_SUCCESS: {
      // state.discounts.pop()
      return {
        ...state,
        loading: false,
        discounts: [action.payload, ...state.discounts],
        total: state.total + 1,
      };
    }
    case DiscountActionTypes.UPDATE_DISCOUNT_SUCCESS: {
      const discounts = replaceInArray(state.discounts, action.payload._id, action.payload);
      return { ...state, loading: false, discounts: [...discounts] };
    }
    case DiscountActionTypes.DELETE_DISCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        discounts: [...state.discounts.filter((discounts: IDiscount) => discounts._id !== action.payload)],
        total: state.total - 1,
      };
    }
    case DiscountActionTypes.GET_DISCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        discounts: action.payload.discounts,
        total: action.payload.total,
      };
    }
    case DiscountActionTypes.CLEAN_ERRORS: {
      return { ...state, loading: false, error: null };
    }
    default: {
      return state;
    }
  }
};

export { reducer as DiscountReducer };
