import { DeleteOutlined, RightOutlined } from '@ant-design/icons';
import React, { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from '../../../models/ILocation';
import { IService } from '../../../models/IService';

export const ServicesCartItem = (props: PropsWithChildren<ServicesCartItemProps>) => {
  const { t } = useTranslation();

  const handleClick = (event: any) => {
    if (!props.disabled && !event.target.closest('.clear-button')) {
      props.onClick();
    }
  };

  const handleServiceRemove = (service: IService) => {
    const services = [...props.services];
    const index = services.findIndex(({ _id }) => _id === service._id);
    services.splice(index, 1);
    props.onChange(services);
  };

  return (
    <li
      className={'list-item' + (props.disabled ? ' list-item--disabled' : '')}
      onClick={handleClick}
    >
      <div className="flex align-items-center">
        <div className="icon-slot flex align-items-center justify-content-center flex-shrink-0">{props.avatar || ''}</div>
        {props.services.length ? (
          <div className="flex flex-column flex-grow-1">
            <div className="text-12 color-gray">{t('Services')}</div>
            <div className="text-16">{props.totalPrice} {props.location.currency}</div>
          </div>
        ) : t('Choose a service')}
        {!!props.services.length && (
          <div className="icon-button flex align-items-center justify-content-center flex-shrink-0">
            <RightOutlined />
          </div>
        )}
      </div>
      {!!props.services.length && (
        <ul className="services-list">
          {props.services.map(service => (
            <li key={service._id} className="flex align-items-center">
              <div className="flex flex-column flex-grow-1">
                <div className="text-16 text-bold">{service.price} {props.location.currency}</div>
                <div className="text-14">{service.name}</div>
              </div>
              <div
                className="icon-button clear-button flex align-items-center justify-content-center flex-shrink-0"
                onClick={() => handleServiceRemove(service)}
              >
                <DeleteOutlined />
              </div>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

interface ServicesCartItemProps {
  avatar?: ReactElement | null | '';
  disabled?: boolean;
  location: ILocation;
  services: IService[];
  totalPrice: number;
  onChange: (services: IService[]) => void;
  onClick: () => void;
}
