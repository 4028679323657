import { action } from '../utils';
import { AppActionTypes, WidgetScreen } from './types';

export const setWidgetHeader = (title: string, subtitle?: string) =>
  action(AppActionTypes.SET_WIDGET_HEADER, { title, subtitle });

export const setWidgetOptions = (locationIds: string[], aboutUs: string) =>
  action(AppActionTypes.SET_WIDGET_OPTIONS, { aboutUs, locationIds });

export const setWidgetScreen = (screen: WidgetScreen) => action(AppActionTypes.SET_WIDGET_SCREEN, screen);

export const unsetWidgetHeader = () => action(AppActionTypes.UNSET_WIDGET_HEADER);

export const setScreenData = (screenData: Record<string, any>) => action(AppActionTypes.SET_SCREEN_DATA, screenData);

export const unsetScreenData = () => action(AppActionTypes.UNSET_SCREEN_DATA);
