import { Reducer } from 'redux';
import { LocationActionTypes, LocationState } from './types';
import { IMaster } from '../../models/IUser';
import { ILocation } from '../../models/ILocation';

export const initialState: LocationState = {
  currentLocation: null,
  widget: null,
  locations: [] as ILocation[],
  error: null,
  loading: false,
  baseStatistics: null,
};

const reducer: Reducer<LocationState> = (state = initialState, action) => {
  switch (action.type) {
    case LocationActionTypes.GET_LOCATION_STATISTICS_REQUEST:
    case LocationActionTypes.GET_LOCATION_WIDGET_REQUEST:
    case LocationActionTypes.CREATE_LOCATION_WIDGET_REQUEST:
    case LocationActionTypes.UPDATE_LOCATION_WIDGET_REQUEST:
    case LocationActionTypes.UPDATE_LOCATION_REQUEST:
    case LocationActionTypes.CLIENT_LOCATION_REQUEST:
    case LocationActionTypes.SELECT_LOCATION_REQUEST:
    case LocationActionTypes.ADD_MASTER_REQUEST:
    case LocationActionTypes.LOCATION_REQUEST: {
      return { ...state, loading: true };
    }
    case LocationActionTypes.LOCATION_REQUEST_FAILURE: {
      return { ...state, loading: false, error: action.payload };
    }
    case LocationActionTypes.CLEAR_STATISTICS: {
      return { ...state, loading: false, baseStatistics: null };
    }
    case LocationActionTypes.GET_LOCATION_STATISTICS_SUCCESS: {
      return { ...state, loading: false, baseStatistics: action.payload };
    }
    case LocationActionTypes.CREATE_LOCATION_WIDGET_SUCCESS:
    case LocationActionTypes.UPDATE_LOCATION_WIDGET_SUCCESS:
    case LocationActionTypes.GET_LOCATION_WIDGET_SUCCESS: {
      return { ...state, loading: false, widget: action.payload };
    }
    case LocationActionTypes.REMOVE_MASTER: {
      return {
        ...state,
        loading: false,
        currentLocation: {
          ...state.currentLocation,
          // @ts-ignore
          masters: state.currentLocation?.masters.filter((master: IMaster) => master._id !== action.payload),
        },
      };
    }
    case LocationActionTypes.CLIENT_LOCATION_SUCCESS: {
      return { ...state, loading: false, locations: action.payload };
    }
    case LocationActionTypes.SET_UP_LOCATION: {
      return {
        ...state,
        loading: false,
        currentLocation: action.payload.currentLocation,
        locations: action.payload.locations,
      };
    }
    case LocationActionTypes.CREATE_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentLocation: action.payload,
        locations: [
          ...state.locations,
          {
            _id: (action.payload as ILocation)._id,
            name: (action.payload as ILocation).name,
          } as ILocation,
        ],
      };
    }
    case LocationActionTypes.SELECT_LOCATION_SUCCESS:
    case LocationActionTypes.LOCATION_SUCCESS: {
      return { ...state, loading: false, currentLocation: action.payload };
    }
    case LocationActionTypes.ADD_MASTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentLocation: {
          ...state.currentLocation,
          masters: state.currentLocation?.masters.concat(action.payload),
        },
      };
    }
    case LocationActionTypes.CLEAN_ERRORS: {
      return { ...state, loading: false, error: null };
    }
    default: {
      return state;
    }
  }
};

export { reducer as LocationReducer };
