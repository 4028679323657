import React, { useMemo } from 'react';
import { ILocation } from '../../../models/ILocation';
import { IReservation } from '../../../models/IReservation';
import { Reservation } from './Reservation/Reservation';
import './Reservations.css';

export const Reservations = (props: ReservationsProps) => {
  const keyedLocations = useMemo<Record<string, ILocation>>(() => props.locations.reduce((locations, location) =>
    Object.assign(locations, { [location._id]: location }), {}), [props.locations]);

  return (
    <ul className="skeedee-list bookings-reservations">
      {props.reservations.map((reservation, index) => (
        <Reservation
          key={index}
          location={keyedLocations[reservation.locationId]}
          reservation={reservation}
          onReservationCancel={props.onReservationCancel}
          onReservationRepeat={props.onReservationRepeat}
        />
      ))}
    </ul>
  );
};

type ReservationsProps = {
  locations: ILocation[];
  reservations: IReservation[];
  onReservationCancel: (reservation: IReservation) => void;
  onReservationRepeat: (reservation: IReservation) => void;
};
